import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./CreateProject.module.css";
import { nanoid } from "nanoid";
import {
  fetchDeliveryManagers,
  fetchHierarchyOptions,
  fetchProjectManagers,
} from "../../services/createProjectServices/Services";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { NavLink } from "react-router-dom";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import logo from "../../assets/IGS.png";
import { Dialog } from "primereact/dialog";
import { UserContext } from "../../context/UserContext";
import { InputText } from "primereact/inputtext";
import { TbArrowBack } from "react-icons/tb";
import { Menubar } from "primereact/menubar";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";

const CreateProject = () => {
  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Project Updated Successfully",
      life: 1000,
    });
  };
  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "Unable to Create Project",
      life: 3000,
    });
  };
  const showWarn = () => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: "Project manager already selected",
      life: 3000,
    });
  };
  const location = useLocation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const projectData = location.state;
  const [project, setProject] = useState(
    projectData !== null
      ? {
          ...projectData.project_details,
          project_managers: projectData.project_details.project_managers.map(
            (manager) => ({
              ...manager,
              empId: manager.empId, // Ensure empId is included
            })
          ),
          delivery_manager: {
            ...projectData.project_details.delivery_manager,
            empId: projectData.project_details.delivery_manager.empId, // Ensure empId is included
          },
        }
      : {
          name: "",
          start_date: "",
          end_date: "",
          project_id: nanoid(),
          delivery_manager: {
            _id: "uuid",
            name: "",
            empId: "", // Add empId here
          },
          project_managers: [],
          team_size: null,
          billing_type: "Monthly",
          team_roles: [],
          client_name: "",
        }
  );

  const [originalProject, setOriginalProject] = useState(
    projectData
      ? {
          ...projectData.project_details,
          project_managers: projectData.project_details.project_managers.map(
            (manager) => ({
              ...manager,
              empId: manager.empId,
            })
          ),
          delivery_manager: {
            ...projectData.project_details.delivery_manager,
            empId: projectData.project_details.delivery_manager.empId,
          },
        }
      : null
  );
  const [projectManagers, setProjectManagers] = useState([]);
  const [deliveryManagers, setDeliveryManagers] = useState([]);
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [isModalVisible, setModalVisible] = useState(false);
  const [changeReason, setChangeReason] = useState("");
  const [changeDetails, setChangeDetails] = useState({});
  const today = new Date().toISOString().split("T")[0];
  const [reasonError, setReasonError] = useState("");

  useEffect(() => {
    // Function to fetch project managers
    const fetchManagers = () => {
      setTimeout(() => {
        if (projectManagers.length === 0) {
          fetchProjectManagers().then((data) => {
            if (data != null && Array.isArray(data)) {
              const managers = data.map((manager) => ({
                id: manager._id,
                name: `${manager.name}`,
                empId: `${manager.empId}`,
              }));
              setProjectManagers(managers);
            }
          });
        }
      }, 200); // Timeout for project managers

      // Fetch delivery managers
      setTimeout(() => {
        if (deliveryManagers.length === 0) {
          fetchDeliveryManagers().then((data) => {
            if (data != null && Array.isArray(data)) {
              const managers = data.map((manager) => ({
                id: manager._id,
                name: `${manager.name}`,
                empId: `${manager.empId}`,
              }));
              setDeliveryManagers(managers);
            }
          });
        }
      }, 400); // Timeout for delivery managers

      // Fetch hierarchy options
      setTimeout(() => {
        if (hierarchyOptions.length === 0) {
          fetchHierarchyOptions().then((data) => {
            if (data != null && Array.isArray(data)) {
              // Filter out items where key is '111' or '110'
              const filteredData = data.filter(
                (option) => option.key !== "111" && option.key !== "110"
              );
              setHierarchyOptions(filteredData);
            } else {
              setHierarchyOptions([]);
            }
          });
        }
      }, 600); // Timeout for hierarchy options
    };

    fetchManagers();
  }, []); // Empty dependency array ensures the effect only runs once after the component mounts

  const handleProjectChange = (field, value) => {
    setProject((prevProject) => {
      const updatedProject = { ...prevProject, [field]: value };

      // Example of handling end_date validation (as in your original code)
      if (field === "end_date") {
        const startDate = new Date(updatedProject.start_date);
        const endDate = new Date(value);

        if (startDate && endDate && endDate < startDate) {
          // Set validation error
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            end_date: "End date cannot be before start date.",
          }));
          return prevProject; // Return previous state if validation fails
        } else {
          // Clear validation error
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            end_date: "",
          }));
        }
      }

      // Return the updated project state
      return updatedProject;
    });
  };

  const handleDeliveryManagerChange = (field, value, empId) => {
    setProject((prevProject) => ({
      ...prevProject,
      delivery_manager: {
        ...prevProject.delivery_manager,
        [field]: value,
        empId: empId, // Set empId
      },
    }));
  };

  const handleProjectManagerChange = (managerId, managerName, empId) => {
    const alreadySelected = project.project_managers.some(
      (manager) => manager.empId === empId
    );

    if (alreadySelected) {
      // First warning message
      showWarn("Project Manager already selected");
    } else {
      setProject((prevProject) => ({
        ...prevProject,
        project_managers: [
          ...prevProject.project_managers,
          { _id: managerId, name: managerName, empId: empId },
        ],
      }));
    }
  };

  console.log(project);
  const handleRemoveProjectManager = (index) => {
    setProject((prevProject) => ({
      ...prevProject,
      project_managers: prevProject.project_managers.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleAddTeamRole = (role) => {
    const roleData = JSON.parse(role);

    // Add new role with initial count of 0
    setProject((prevProject) => ({
      ...prevProject,
      team_roles: [
        ...prevProject.team_roles,
        { name: roleData, billing_rate: null, count: 0 }, // Start count at 0 for new roles
      ],
    }));
  };

  const handleTeamRoleChange = (index, field, value) => {
    const newRoles = [...project.team_roles];
    const newValue = field === "count" ? parseInt(value, 10) : value;

    // Calculate the total count if the new value is added
    const newRolesWithNewValue = [...newRoles];
    newRolesWithNewValue[index][field] = newValue;

    const totalCount = newRolesWithNewValue.reduce(
      (sum, role) => sum + parseInt(role.count || 0, 10),
      0
    );

    // If the total count exceeds the team size, show a warning but allow adding the role/count
    if (totalCount > project.team_size) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Total employee count exceeds team size",
        life: 3000,
      });
    }

    // Update the state with the new count, even if it exceeds the limit
    setProject((prevProject) => ({
      ...prevProject,
      team_roles: newRolesWithNewValue,
    }));
  };

  const handleRemoveTeamRole = (index) => {
    try {
      console.log("Attempting to remove team role at index:", index);

      // Update the project state
      setProject((prevProject) => ({
        ...prevProject,
        team_roles: prevProject.team_roles.filter((_, i) => i !== index),
      }));

      console.log("Successfully removed team role at index:", index);
    } catch (error) {
      console.error("Error removing team role:", error);
      // Optionally, show a user-friendly error message
      alert(
        "An error occurred while removing the team role. Please try again."
      );
    }
  };
 
  // const handleRemoveTeamRole = (index) => {
  //   const updatedRoles = project.team_roles.filter((_, i) => i !== index);

  //   setProject((prevProject) => ({
  //     ...prevProject,
  //     team_roles: updatedRoles,
  //   }));
  // };

  const validateProject = () => {
    const errors = {};

    if (!project.name) {
      errors.name = "Project name is required";
    }
    if (!project.start_date) {
      errors.start_date = "Start date is required";
    }
    if (!project.end_date) {
      errors.end_date = "End date is required";
    }
    if (!project.delivery_manager.name) {
      errors.delivery_manager = "Delivery manager is required";
    }
    if (!project.client_name) {
      errors.client_name = "Client name is required";
    }
    if (!project.team_size) {
      errors.team_roles = "team size is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    // Perform validation
    const isValid = validateProject();

    if (!isValid) {
      // If validation fails, show errors and return
      return;
    }

    // If validation passes, proceed with the save logic
    if (projectData) {
      // Edit: Show the modal before saving changes
      const updatedData = compareData(originalProject, project);
      setChangeDetails(updatedData);
      setModalVisible(true); // Show the modal for editing
    } else {
      // Create: Directly call the create API
      handleCreateProject();
    }
  };

  const handleCreateProject = async () => {
    // Build the projectDetails object with the correct structure
    const projectDetails = {
      name: project.name, // Ensure these fields exist in your project object
      start_date: project.start_date,
      end_date: project.end_date,
      project_id: project.project_id,
      billing_type: project.billing_type,
      delivery_manager: project.delivery_manager,
      project_managers: project.project_managers,
      team_size: project.team_size,
      team_roles: project.team_roles,
      client_name: project.client_name,
    };

    try {
      const createResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/project/create`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...projectDetails,
          }),
        }
      );

      const createResult = await createResponse.json();

      if (createResponse.ok) {
        showSuccess(); // Show the success toast
        console.log("Project created successfully:", createResult);

        // Use setTimeout to navigate after 3 seconds (3000ms)
        setTimeout(() => {
          navigate("/projects");
        }, 1000);
      } else {
        showError("Project creation failed");
      }
    } catch (error) {
      console.error("An error occurred during project creation:", error);
      showError();
    }
  };

  const compareData = (oldData, newData) => {
    return {
      previous_data: oldData,
      updated_data: newData,
    };
  };

  const handleSaveChanges = async () => {
    if (!changeReason.trim()) {
      setReasonError("Reason is required");
      return;
    }

    setReasonError("");
    // Ensure user.Data is available and has a name property
    const userName = user?.Data?.name || "Default Name";

    // Build the projectDetails object with the correct structure
    const projectDetails = {
      name: project.name,
      start_date: project.start_date,
      end_date: project.end_date,
      project_id: project.project_id,
      billing_type: project.billing_type,
      delivery_manager: project.delivery_manager,
      project_managers: project.project_managers,
      team_size: project.team_size,
      team_roles: project.team_roles,
      client_name: project.client_name,
    };

    const modifiedBy = {
      name: userName,
    };

    const payload = {
      projectDetails: projectDetails,
      reason: changeReason,
      modifiedBy: modifiedBy,
    };

    try {
      const editResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/project/edit`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...payload,
            modified_at: new Date().toISOString(),
          }),
        }
      );

      const editResult = await editResponse.json();
      if (editResponse.ok) {
        showSuccess();
        console.log("Project edited successfully:", editResult);
        setTimeout(() => {
          navigate("/projects");
        }, 1000);
      } else {
        showError();
      }
    } catch (error) {
      console.error("An error occurred during project editing:", error);
      showError();
    }

    setModalVisible(false);
  };

  const handleCancel = () => {
    showWarn();
  };
  const start = (
    <div>
      <button
        className="rounded-full  hover:bg-gray-200"
        style={{ height: "40px", width: "40px" }}
        onClick={() => navigate(-1)}
      >
        <TbArrowBack />
      </button>
    </div>
  );
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  return (
    <div>
      <Menubar start={start} end={end} />
      <div className={styles.container}>
        <form className={styles.form}>
          <div className={styles.column}>
            <div className={styles.field}>
              <label>Project Name</label>
              <InputText
                type="text"
                value={project.name}
                onChange={(e) => handleProjectChange("name", e.target.value)}
                placeholder="Enter your project name here"
              />
              {validationErrors.name && (
                <small className={styles.error}>{validationErrors.name}</small>
              )}
            </div>
            <div className={styles.field}>
              <label>Client Name</label>
              <InputText
                type="text"
                value={project.client_name}
                onChange={(e) =>
                  handleProjectChange("client_name", e.target.value)
                }
                placeholder="Enter your client name here"
              />
              {validationErrors.client_name && (
                <small className={styles.error}>
                  {validationErrors.client_name}
                </small>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div className={styles.field} style={{ width: "100%" }}>
                <label>Start Date</label>
                <InputText
                  type="date"
                  onFocus={(e) => e.target.showPicker()}
                  value={project.start_date}
                  // min={today} // Set min attribute to today
                  onChange={(e) =>
                    handleProjectChange("start_date", e.target.value)
                  }
                />
                {validationErrors.start_date && (
                  <small className={styles.error}>
                    {validationErrors.start_date}
                  </small>
                )}
              </div>
              <div className={styles.field} style={{ width: "100%" }}>
                <label>End Date</label>
                <InputText
                  type="date"
                  onFocus={(e) => e.target.showPicker()}
                  value={project.end_date}
                  // min={today} // Set min attribute to today
                  onChange={(e) =>
                    handleProjectChange("end_date", e.target.value)
                  }
                />
                {validationErrors.end_date && (
                  <small className={styles.error}>
                    {validationErrors.end_date}
                  </small>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <label>Billing Type</label>
              <Dropdown
                value={project.billing_type} // Make sure this is correctly tied to the state
                options={[
                  { label: "Monthly", value: "Monthly" },
                  { label: "Daily", value: "Daily" },
                  { label: "Hourly", value: "Hourly" },
                ]}
                onChange={(e) => handleProjectChange("billing_type", e.value)} // Correctly update the state
                style={{ width: "100%" }}
              />
              {validationErrors.billing_type && (
                <small className={styles.error}>
                  {validationErrors.billing_type}
                </small>
              )}
            </div>

            <div className={styles.field}>
              <label>Team Size</label>
              <InputText
                type="number"
                value={project.team_size}
                onChange={(e) =>
                  handleProjectChange("team_size", e.target.value)
                }
                placeholder="Team size for the project"
                min="0"
              />
              {validationErrors.team_roles && (
                <small className={styles.error}>
                  {validationErrors.team_roles}
                </small>
              )}
            </div>

            <div className={styles.field}>
              <label>Delivery Manager</label>
              <Dropdown
                style={{ width: "100%" }}
                value={project.delivery_manager.empId} // Use empId for value
                options={deliveryManagers.map((manager) => ({
                  label: manager.name,
                  value: manager.empId,
                }))}
                onChange={(e) => {
                  const selectedManager = deliveryManagers.find(
                    (manager) => manager.empId === e.value
                  );
                  if (selectedManager) {
                    handleDeliveryManagerChange(
                      "name",
                      selectedManager.name,
                      selectedManager.empId
                    );
                  }
                }}
                placeholder="Select the delivery manager for this project"
              />
              {validationErrors.delivery_manager && (
                <small className={styles.error}>
                  {validationErrors.delivery_manager}
                </small>
              )}
            </div>
            <div className={styles.field}>
              <label>Resource Manager</label>
              <Dropdown
                style={{ width: "100%" }}
                options={projectManagers.map((manager) => ({
                  label: manager.name,
                  value: manager.empId,
                }))}
                onChange={(e) => {
                  const selectedManager = projectManagers.find(
                    (manager) => manager.empId === e.value
                  );
                  if (selectedManager) {
                    handleProjectManagerChange(
                      selectedManager.id,
                      selectedManager.name,
                      selectedManager.empId
                    );
                  }
                }}
                placeholder="Select the project manager for this project"
              />
            </div>

            <div className={styles.managersList}>
              {project.project_managers.length > 0 &&
                project.project_managers.map((manager, index) => (
                  <div className={styles.manager} key={index}>
                    <span style={{ fontSize: "14px" }}>{manager.name}</span>
                    <button 
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the form from submitting
                      handleRemoveProjectManager(index);
                    }}
                    >
                      <i
                        className="pi pi-trash"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <Divider layout="vertical" />
          <div className={styles.column}>
            <div className={styles.field}>
              <label>Team Roles</label>
              <select
                onChange={(e) => {
                  handleAddTeamRole(e.target.value);
                  e.target.value = "";
                }}
              >
                <option value="">--Add Team Role--</option>
                {Array.isArray(hierarchyOptions) &&
                  hierarchyOptions.map((option) => (
                    <option key={option.key} value={JSON.stringify(option)}>
                      {option.value}
                    </option>
                  ))}
              </select>
            </div>
            {project.team_roles.length > 0 &&
              project.team_roles.map((role, index) => (
                <div className={styles.role} key={index}>
                  <InputText
                    type="text"
                    value={role.name.value}
                    onChange={(e) =>
                      handleTeamRoleChange(index, "name", e.target.value)
                    }
                    placeholder="Role Name"
                    disabled
                    style={{ fontWeight: "bold", width: "60%" }} // Makes the text bold
                  />

                  <div className="flex items-center border rounded w-1/3">
                    <span className="p-2 border-r">₹</span>
                    <InputText
                      type="number"
                      value={role.billing_rate}
                      onChange={(e) =>
                        handleTeamRoleChange(
                          index,
                          "billing_rate",
                          e.target.value
                        )
                      }
                      placeholder="Billing Rate"
                      className="p-2 w-full"
                      min="0"
                    />
                    {validationErrors.team_roles && (
                      <small className={styles.error}>
                        {validationErrors.team_roles}
                      </small>
                    )}
                  </div>
                  <InputText
                    type="number"
                    value={role.count}
                    onChange={(e) =>
                      handleTeamRoleChange(index, "count", e.target.value)
                    }
                    placeholder="No. of Employee"
                    min="0"
                  />
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the form from submitting
                      handleRemoveTeamRole(index);
                    }}
                  >
                    <i
                      className="pi pi-trash"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                  </button>
                </div>
              ))}
          </div>
        </form>
        <div className={styles.buttons}>
          <Toast ref={toast} />
          <Button onClick={handleSubmit}>Save</Button>
          <NavLink to="/projects">
            <Button onClick={handleCancel} severity="danger">
              Cancel
            </Button>
          </NavLink>
        </div>

        <Dialog
          header="Project Log"
          style={{ width: "42vw", height: "45vh" }}
          visible={isModalVisible}
          onHide={() => setModalVisible(false)}
        >
          <div style={{ padding: "20px" }}>
            <div className="p-field pb-2">
              <InputTextarea
                style={{
                  width: "80vh",
                  height: "20vh",
                  border: "1px solid black",
                }}
                placeholder="Reason ...."
                id="TaskName"
                value={changeReason}
                onChange={(e) => setChangeReason(e.target.value)}
                rows={5}
                cols={30}
              />
              {reasonError && (
                <small style={{ color: "red" }}>{reasonError}</small>
              )}
            </div>
            <div
              className="p-field"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                style={{ backgroundColor: "#182452", width: "6vw" }}
                label="Add"
                onClick={handleSaveChanges}
              />
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default CreateProject;
