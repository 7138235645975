import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.png";

// Define resetFields outside the component
const resetFields = [
  {
    label: "Email",
    type: "email",
    id: "email-input",
    name: "email",
    placeholder: "Email", // Add placeholder here
    icon: <i className="pi pi-user" style={{ position: "absolute", top: "50%", left: "1rem", transform: "translateY(-50%)", color: "#888" }}></i>,
  },
  {
    label: "Password",
    type: "password",
    id: "password-input",
    name: "password",
    placeholder: "Enter Password", // Add placeholder here
    icon: <i className="pi pi-lock" style={{ position: "absolute", top: "50%", left: "1rem", transform: "translateY(-50%)", color: "#888" }}></i>,
  },
  {
    label: "Retype Password",
    type: "password",
    id: "repassword-input",
    name: "repassword",
    placeholder: "Re-type Password", // Add placeholder here
    icon: <i className="pi pi-lock" style={{ position: "absolute", top: "50%", left: "1rem", transform: "translateY(-50%)", color: "#888" }}></i>,
  },
];

const SetPassword = () => {
  const [resetForm, setResetForm] = useState({
    email: "",
    password: "",
    repassword: "",
  });
  const [focusedField, setFocusedField] = useState(null); // Track which input is focused
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#f9f9f9",
    },
    formContainer: {
      backgroundColor: "white",
      padding: "2rem",
      borderRadius: "8px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      width: "23%",
    },
    logo: {
      display: "block", // Ensures it's treated as a block-level element
      margin: "0 auto", // Centers the logo horizontally
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    title: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    inputContainer: {
      marginBottom: "1rem",
      textAlign: "left",
    },
    input: {
      width: "100%",
      padding: "0.75rem",
      paddingLeft: "2.5rem",
      fontSize: "1rem",
      border: "1px solid #ddd",
      borderRadius: "4px",
      outline: "none",
      transition: "border-color 0.3s ease",
    },
    inputFocus: {
      borderColor: "#007BFF", // Blue color for focus
    },
    button: {
      width: "100%",
      padding: "0.75rem",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "white",
      backgroundColor: "#000066",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    errorMessage: {
      color: "red",
      marginTop: "0.5rem",
    },
    successMessage: {
      marginTop: "20px",
      padding: "15px",
      border: "1px solid #38B2AC",
      backgroundColor: "#E6FFFA",
      borderRadius: "5px",
      color: "#38B2AC",
      textAlign: "center",
    },
    icon: {
      position: "absolute",
      left: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      color: "#aaa",
    },
  };
  const handleSetPassword = async () => {
    if (resetForm.password !== resetForm.repassword) {
      setError(true);
      setSuccess(false);
      return;
    }

    try {
      setError(false);
      const response = await fetch(
        `${process.env.REACT_APP_UPDATE_URL}${process.env.REACT_APP_UPDATE_ENDPOINT}/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          employeeEmail: resetForm.email,
          newPassword: resetForm.password,
        }),
      });

      const data = await response.json();

      if (response.ok && data.status === "Success") {
        setSuccess(true);
        setError(false);
        // setTimeout(() => navigate("/login"), 2000); // Automatically redirect after 2 seconds
      } else {
        setError(true);
        setSuccess(false);
        console.error("Error updating password:", data.message || "Unknown error");
      }
    } catch (error) {
      setError(true);
      setSuccess(false);
      console.error("Error updating password:", error);
    }
  };
  
  return (
    <section style={styles.container}>
      <div style={styles.formContainer}>
        <img src={logo} alt="Logo" style={styles.logo} width={100} />
        <h1 style={styles.title}>IGS Timesheet</h1>
        <h2 style={styles.title}>Set Password</h2>
        {resetFields.map((field) => (
          <div key={field.id} style={{ position: "relative", ...styles.inputContainer }}>
            {field.icon}
            <input
              type={field.type}
              id={field.id}
              name={field.name}
              placeholder={field.placeholder} // Use the placeholder property here
              style={{
                ...styles.input,
                ...(focusedField === field.name ? styles.inputFocus : {}),
              }}
              onFocus={() => setFocusedField(field.name)}
              onBlur={() => setFocusedField(null)}
              onChange={(e) => {
                setResetForm((prev) => ({ ...prev, [field.name]: e.target.value }));
                setError(false);
              }}
            />
          </div>
        ))}
         {error && <p style={styles.errorMessage}>Passwords do not match or update failed</p>}
         <button
        type="button"
        style={styles.button}
        onClick={success ? () => navigate("/login") : handleSetPassword}
      >
        {success ? "Back To Login" : "Set Password"}
      </button>

      {/* Success Message */}
      {success && (
        <div style={styles.successMessage}>
          <h3 className="font-semibold text-gray-800">Password Successfully Set</h3>
          <p className="text-sm text-gray-700">
            You have successfully updated your password.
          </p>
        </div>
      )}
      </div>
     
    </section>
  );
};

export default SetPassword;
