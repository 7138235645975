// import React, { createContext, useEffect, useState } from "react";

// // Create the context
// export const UserContext = createContext();

// // Create the provider component
// export const UserProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   useEffect(() => {
//     // Check if user data is in localStorage
//     const savedUser = localStorage.getItem("user");
//     if (savedUser) {
//       setUser(JSON.parse(savedUser));
//     }
//   }, []);
//   return (
//     <UserContext.Provider value={{ user, setUser }}>
//       {children}
//     </UserContext.Provider>
//   );
// };


import React, { createContext, useEffect, useState } from "react";

// Create the context
export const UserContext = createContext();

// Create the provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Retrieve and parse user data from localStorage
    const savedUser = localStorage.getItem("user");
    console.log("Saved user data from localStorage:", savedUser); // Debugging log
    if (savedUser) {
      try {
        const parsedUser = JSON.parse(savedUser);

        // Wrap the parsed user data in a "Data" object
        const formattedUser = { Data: parsedUser };

        console.log("Formatted user data:", formattedUser); // Debugging log
        setUser(formattedUser); // Set formatted data in state
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
