import React, { useEffect, useState } from "react";
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import logo from "../../../../assets/IGS.png";
import { FaCalculator } from "react-icons/fa";
import BillingModal from "./BillingModal";
import { Dialog } from "primereact/dialog";
import DownloadExcel from "../../../Leaves/DownloadExcel";
import { MdFeaturedPlayList } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ProjectReport = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  //   const [selectedProject, setSelectedProject] = useState("All");
  const [selectedProject, setSelectedProject] = useState("Monthly");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [billingModalVisible, setBillingModalVisible] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(""); // Initially empty

  useEffect(() => {
    // Fetch data from the API
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getProjectReport`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.data && data.data.length > 0) {
          // Filter out objects where any key value is "NaN"
          const filteredData = data.data.filter(
            (item) => !Object.values(item).some((value) => value === "NaN")
          );

          // Remove duplicates by serializing the entire object as a string
          const uniqueData = Array.from(
            new Map(
              filteredData.map((item) => [JSON.stringify(item), item])
            ).values()
          );

          setData(uniqueData);
          setFilteredData(uniqueData);
          setSelectedMonth("All");
        } else {
          setData([]); // Set empty array if no data available
          setSelectedMonth("All"); // Ensure "All" is selected if no data
        }
        setLoading(false); // Stop loader
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]); // Set empty array on error
        setSelectedMonth("All"); // Ensure "All" is selected on error
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    let filtered = data;

    // Filter by selected billing type (selectedProject)
    if (selectedProject) {
      filtered = filtered.filter(
        (item) => item.billing_type === selectedProject
      );
    }

    // Filter by selected month (selectedMonth)
    if (selectedMonth && selectedMonth !== "All") {
      filtered = filtered.filter((item) => item.monthname === selectedMonth);
    }

    // Filter by search term
    if (searchTerm !== "") {
      filtered = filtered.filter(
        (item) =>
          item.employeeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.empId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.projectName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredData(filtered);
  }, [selectedProject, selectedMonth, searchTerm, data]);
  // Get unique project names for the dropdown (without "All")
  const projectNames =
    data.length > 0
      ? [...new Set(data.map((item) => item.billing_type))].map((name) => ({
          label: name,
          value: name,
        }))
      : [
          {
            label: "No Billing Report Available",
            value: "No Billing Report Available",
          },
        ];

  // Get unique and sorted month names for the dropdown
  // Get unique and sorted month names for the dropdown, including "All"
  const monthNames = [
    { label: "All", value: "All" }, // Add "All" option
    ...(data.length > 0
      ? Array.from(new Set(data.map((item) => item.monthname)))
          .filter(Boolean) // Ensure no null or undefined values
          .map((month) => ({
            label: String(month), // Convert to string
            value: String(month), // Convert to string
          }))
      : [{ label: "No Month Available", value: "No Month Available" }]),
  ];

  // Automatically select "All" on first load
  useEffect(() => {
    if (monthNames.length > 0 && !selectedMonth) {
      setSelectedMonth(monthNames[0].value); // Select "All" initially
    }
  }, [monthNames, selectedMonth]);

  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "22px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
        Billing Report
      </p>
    </div>
  );

  const end = (
    <div className="pr-4">
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  if (loading) {
    return <div className="text-center mt-24">Loading...</div>;
  }
  const formatSalary = (salary) => {
    let [wholePart, decimalPart] = salary.toString().split(".");
    wholePart = wholePart
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(/(\d+)(\d{2},)/, "$1,$2");
    decimalPart = decimalPart ? decimalPart.slice(0, 2) : "00";
    return `${wholePart}.${decimalPart}`;
  };

  return (
    <div>
      <div className="card">
        <Menubar start={start} end={end} />
      </div>
      <div
        style={{
          backgroundColor: "#f8f9fa",
          height: "calc(100vh - 140px)",
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          marginTop: "10px",
          overflow: "scroll",
          position: "relative",
        }}
      >
        <div className="p-1">
          {/* Filter Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="flex"
              style={{
                width: "70%",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "5px",
                gap: "10px",
              }}
            >
              {/* Project Filter Dropdown */}
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-start",
                  // paddingRight: "50px",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "bold",
                    color: "#00000080",
                    fontSize: "18px",
                  }}
                >
                  Project:
                </label>
                <Dropdown
                  value={selectedProject}
                  options={projectNames}
                  onChange={(e) => setSelectedProject(e.value)}
                  style={{ width: "100%" }}
                  placeholder="Select a Project"
                />
              </div>
              <div
                style={{
                  width: "20%",
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-start",
                  // paddingRight: "50px",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "bold",
                    color: "#00000080",
                    fontSize: "18px",
                  }}
                >
                  Month:
                </label>
                <Dropdown
                  value={selectedMonth}
                  options={monthNames}
                  onChange={(e) => setSelectedMonth(e.value)} // Ensure the selected value is set
                  style={{ width: "100%" }}
                  placeholder="Select a Month"
                />
              </div>
              {/* Search Input */}
              <div className="flex items-center" style={{ width: "20%" }}>
                <InputText
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  className="w-full md:w-65"
                />
              </div>
            </div>
            <div
              className="flex"
              style={{
                width: "40%",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "20px",
                gap: "10px",
              }}
            >
              <div>
                <DownloadExcel
                  data={filteredData} // Pass the filtered data to the DownloadExcel component
                  fileName="Billing Report" // Set the file name
                  sheetName="Billing Report" // Set the sheet name
                />
              </div>
            </div>
          </div>

          {/* Data Table */}
          <DataTable
            value={filteredData} // Ensure the pagination logic is correct here
            paginator={false}
            className="w-full"
            emptyMessage="No Billing Report Available"
            responsiveLayout="scroll"
            scrollable
            style={{ borderCollapse: "collapse" }} // Ensures borders are collapsed
          >
            <Column
              field="employeeName"
              header="Resource Name"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              bodyStyle={{ fontSize: "14px", border: "1px solid #ccc" }}
            />
            <Column
              field="projectName"
              header="Project Name"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              bodyStyle={{ fontSize: "14px", border: "1px solid #ccc" }}
            />

            <Column
              field="allocation"
              header="Allocation"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            />
            <Column
              field="totalWorkingDays"
              header="Working Days in Month"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              //   body={(rowData) => `${rowData.totalDaysInMonth} Days`}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            />
            <Column
              field="daysWorked"
              header="Days Worked"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              //   body={(rowData) => `${rowData.daysWorked} Days`}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            />
            <Column
              field="paidLeaveDays"
              header="Leaves"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              body={(rowData) => {
                const paidLeaveDays = rowData.paidLeaveDays || 0;
                const halfDayLeaveDays = rowData.halfDayLeaveDays || 0;
                const totalLeaveDays = paidLeaveDays + halfDayLeaveDays;
                return totalLeaveDays.toFixed(1); // Display the sum rounded to 1 decimal place
              }}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            />

            <Column
              field="totalHoursWorked"
              header="Total Hours Worked"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              //   body={(rowData) => `${rowData.totalHoursWorked} Hrs`}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            />
            <Column
              field="monthlyProjectPayment"
              header="Billing Rate"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              //   body={(rowData) => `₹ ${rowData.monthlyProjectPayment} `}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            />
            <Column
              field="totalSalaryFromCompany"
              header="Salary PM"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                width: "9%",
                border: "1px solid #ccc",
              }}
              body={(rowData) =>
                `${formatSalary(rowData.totalSalaryFromCompany)}`
              }
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            />
            {/* <Column
              field="ctcForDaysWorked"
              header="Salary for Days Worked"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              //   body={(rowData) => `₹ ${rowData.ctcForDaysWorked} `}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            /> */}
            {/* <Column
              field="netBilling"
              header="Net Billing"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                width: "8%",
                border: "1px solid #ccc",
              }}
              //   body={(rowData) => `₹ ${rowData.netBilling} `}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            /> */}
            <Column
              field="leavePay"
              header="Billing Loss"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                width: "8%",
                border: "1px solid #ccc",
              }}
              //   body={(rowData) => `₹ ${rowData.netBilling} `}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            />
            {/* <Column
              field="margin"
              header="Margin"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                width: "8%",
                border: "1px solid #ccc",
              }}
              //   body={(rowData) => `₹ ${rowData.margin}`}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            /> */}
            <Column
              field="marginPercentage"
              header="Margin Percentage"
              headerStyle={{
                fontSize: "16px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
              body={(rowData) => {
                const margin = rowData.marginPercentage;
                return isNaN(margin) || margin === null
                  ? "0.00 %"
                  : `${parseFloat(margin).toFixed(2)} %`;
              }}
              bodyStyle={{
                fontSize: "14px",
                textAlign: "center",
                border: "1px solid #ccc",
              }}
            />
          </DataTable>

          {/* Pagination */}
          {/* <Paginator
            first={first}
            rows={rows}
            totalRecords={filteredData.length}
            onPageChange={(e) => setFirst(e.first)}
            className="mt-4"
            style={{ backgroundColor: "#f8f9fa" }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectReport;
