import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Divider } from "primereact/divider";
import {
  format,
  addDays,
  startOfWeek,
  getISOWeeksInYear,
  getYear,
  getISOWeek,
} from "date-fns";
import { UserContext } from "../../../context/UserContext";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import "./timesheet.css";
const Users = () => {
  const { user } = useContext(UserContext);
  const currentYear = getYear(new Date());
  const currentWeek = getISOWeek(new Date());
  const [year, setYear] = useState(currentYear); // Default year
  const [weekNumber, setWeekNumber] = useState(currentWeek);
  const [timesheetsData, setTimesheetsData] = useState([]); // State to store fetched data
  const [projectOptions, setProjectOptions] = useState([]); // State to store project options for the dropdown
  const [selectedProject, setSelectedProject] = useState("all");
  const toast = useRef(null);
  const [expandedEmployees, setExpandedEmployees] = useState([]);
  const [checkBox, setCheckbox] = useState(false);
  const navigate = useNavigate();
  const [selectAllState, setSelectAllState] = useState({});
  const [approvals, setApprovals] = useState({});
  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const [rejectDialogVisible, setRejectDialogVisible] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [rejectError, setRejectError] = useState("");
  const showFullLayout = ["111", 111].includes(user.Data.hkey);

  // Reset checkbox and approval states when project changes
  useEffect(() => {
    setSelectAllState({});
    setApprovals({});
    setSelectedApprovals([]);
  }, [selectedProject]);

  const handleSelectAllChange = (key, checked) => {
    if (key === "all") {
      // Toggle all checkboxes
      const newSelectAllState = {};
      const newApprovals = {};
      const newSelectedApprovals = [];

      Object.keys(groupedData).forEach((employeeName) => {
        newSelectAllState[employeeName] = checked;

        groupedData[employeeName].forEach((rowData) => {
          const rowKey = `${rowData.name}-${rowData.project}-${rowData.subproject}-${rowData.task}`;
          newApprovals[rowKey] = {
            ...rowData,
            checked,
            reason: rejectionReason,
          };
          if (checked) {
            newSelectedApprovals.push(rowData);
          }
        });
      });

      setSelectAllState(newSelectAllState);
      setApprovals(newApprovals);
      setSelectedApprovals(newSelectedApprovals);
    } else {
      // Toggle specific group
      setSelectAllState((prevState) => ({
        ...prevState,
        [key]: checked,
      }));

      const updatedApprovals = {};
      groupedData[key].forEach((rowData) => {
        const rowKey = `${rowData.name}-${rowData.project}-${rowData.subproject}-${rowData.task}`;
        updatedApprovals[rowKey] = {
          ...rowData,
          checked,
          reason: rejectionReason,
        };
      });

      setApprovals((prevApprovals) => ({
        ...prevApprovals,
        ...updatedApprovals,
      }));

      setSelectedApprovals((prevSelected) =>
        checked
          ? [...prevSelected, ...groupedData[key]]
          : prevSelected.filter((row) => row.name !== key)
      );
    }
  };

  const resetCheckboxes = () => {
    // Reset the checkboxes and approvals
    setSelectAllState({});
    setApprovals({});
    setSelectedApprovals([]);
  };

  const fetchTimesheetsData = () => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheets/pending-request/${user.Data.empId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setTimesheetsData(data.timesheets);

          // Extract unique project names and add an "All" option
          const uniqueProjects = [
            { label: "All", value: "all" },
            ...new Map(
              data.timesheets.map((item) => [
                item.project._id,
                { label: item.project.name, value: item.project._id },
              ])
            ).values(),
          ];

          setProjectOptions(uniqueProjects);
          setSelectedProject("all"); // Set "all" as the default selected project
        } else {
          setTimesheetsData([]);
          console.error("Failed to fetch timesheets:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  console.log(timesheetsData);

  useEffect(() => {
    fetchTimesheetsData();
  }, []);

  const totalWeeks = getISOWeeksInYear(new Date(year, 0, 1));
  const currentWeekStart = startOfWeek(new Date(year, 0, 1), {
    weekStartsOn: 0,
  });
  const selectedWeekStart = addDays(currentWeekStart, (weekNumber - 1) * 7);
  const selectedWeekEnd = addDays(selectedWeekStart, 6); // Get the end of the week

  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    daysOfWeek.push(addDays(selectedWeekStart, i));
  }

  const incrementWeek = () => {
    if (weekNumber < totalWeeks) {
      setWeekNumber(weekNumber + 1);
      resetCheckboxes();
    }
  };

  const decrementWeek = () => {
    if (weekNumber > 1) {
      setWeekNumber(weekNumber - 1);
      resetCheckboxes();
    }
  };

  const formattedData = timesheetsData
    .filter((item) => {
      // If "all" is selected, include all projects; otherwise, filter by the selected project
      if (selectedProject === "all") {
        return true;
      }
      return item.project._id === selectedProject;
    })
    .map((item) => {
      const row = {
        name: item.employeeName,
        empId: item.empId,
        taskId: item.task._id,
        week: item.week,
        subproject: item.subproject.name,
        project: item.project.name,
        task: item.task.name,
        projectId: item.project._id,
        subprojectId: item.subproject._id,
        total: item.totalHours, // Directly use totalHours from the response
      };

      // Initialize work hours for each day from the entry
      daysOfWeek.forEach((day) => {
        const dayKey = format(day, "EEE").toLowerCase();
        const dayEntry = item.entries[0]?.hours[dayKey]?.time || "-";

        row[format(day, "dd/MM/yyyy")] = {
          WorkHours: dayEntry,
        };
      });

      row.approved = approvals[item.employeeName] || null;
      return row;
    });

  const onApprovalChange = (e, name, project, subproject, task) => {
    const approvalKey = `${name}-${project}-${subproject}-${task}`; // Unique key for each approval

    // Update the approvals state
    setApprovals((prevApprovals) => ({
      ...prevApprovals,
      [approvalKey]: e.value,
    }));

    // Add or remove from selected approvals
    if (e.value === true) {
      setSelectedApprovals((prevSelected) => [
        ...prevSelected,
        ...formattedData.filter(
          (row) =>
            row.name === name &&
            row.project === project &&
            row.subproject === subproject &&
            row.task === task
        ),
      ]);
    } else {
      setSelectedApprovals((prevSelected) =>
        prevSelected.filter(
          (row) =>
            !(
              row.name === name &&
              row.project === project &&
              row.subproject === subproject &&
              row.task === task
            )
        )
      );
    }
  };

  // const handleApproveSelected = async () => {
  //   try {
  //     const payload = selectedApprovals.map((approval) => ({
  //       empId: approval.empId,
  //       projectId: approval.projectId,
  //       subprojectId: approval.subprojectId,
  //       taskId: approval.taskId,
  //       week: approval.week,
  //     }));

  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheet/approve/${user.Data.name}`,
  //       {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(payload),
  //       }
  //     );

  //     if (response.ok) {
  //       const result = await response.json();

  //       // Check if the response contains an array and handle only the first object
  //       if (Array.isArray(result) && result.length > 0) {
  //         const firstResult = result[0]; // Get the first object in the array
  //         if (firstResult?.success && firstResult?.message) {
  //           // Show success toast notification with the message of the first response object
  //           toast.current.show({
  //             severity: "success",
  //             summary: "Success",
  //             detail: firstResult.message, // Assuming each response object has a message
  //             life: 3000,
  //           });
  //         } else {
  //           // Handle unexpected format of success response
  //           toast.current.show({
  //             severity: "success",
  //             summary: "Success",
  //             detail: "Timesheet approved successfully",
  //             life: 3000,
  //           });
  //         }
  //       } else {
  //         // If response is not an array or is empty, show a generic success message
  //         toast.current.show({
  //           severity: "success",
  //           summary: "Success",
  //           detail: "Timesheet approved successfully",
  //           life: 3000,
  //         });
  //       }
  //       // Clear selected approvals after successful approval
  //       setSelectedApprovals([]);
  //       // Fetch the updated timesheet data
  //       fetchTimesheetsData();
  //     } else {
  //       // Handle error response
  //       const errorDetail = await response.json();
  //       toast.current.show({
  //         severity: "error",
  //         summary: "Error",
  //         detail: errorDetail.message || "Failed to approve timesheet",
  //         life: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error approving selected:", error);
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: "An error occurred while approving timesheet",
  //       life: 3000,
  //     });
  //   }
  // };
  const handleApproveSelected = async () => {
    try {
      const payload = selectedApprovals.map((approval) => ({
        empId: approval.empId,
        projectId: approval.projectId,
        subprojectId: approval.subprojectId,
        taskId: approval.taskId,
        week: approval.week,
      }));

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheet/approve/${user.Data.name}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();

        // Check if the response contains an array and handle only the first object
        if (Array.isArray(result) && result.length > 0) {
          const firstResult = result[0];
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: firstResult.message || "Timesheet approved successfully",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Timesheet approved successfully",
            life: 3000,
          });
        }

        // Clear selected approvals and reset checkbox state
        setSelectedApprovals([]);
        setSelectAllState({}); // Reset all checkboxes to unchecked

        // Fetch the updated timesheet data
        fetchTimesheetsData();
      } else {
        const errorDetail = await response.json();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorDetail.message || "Failed to approve timesheet",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error approving selected:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while approving timesheet",
        life: 3000,
      });
    }
  };

  const handleRejectButtonClick = () => {
    setRejectDialogVisible(true);
  };
  const handleRejectionReasonChange = (e) => {
    setRejectionReason(e.target.value);
  };

  const handleRejectSelected = async () => {
    if (!rejectionReason.trim()) {
      setRejectError("Reason is required");
      return;
    }

    const uniqueApprovals = selectedApprovals.filter(
      (approval, index, self) =>
        index ===
        self.findIndex(
          (a) =>
            a.empId === approval.empId &&
            a.projectId === approval.projectId &&
            a.subprojectId === approval.subprojectId &&
            a.taskId === approval.taskId &&
            a.week === approval.week
        )
    );

    const payload = uniqueApprovals.map((approval) => ({
      empId: approval.empId,
      projectId: approval.projectId,
      subprojectId: approval.subprojectId,
      taskId: approval.taskId,
      week: approval.week,
      reason: rejectionReason,
    }));

    console.log("Payload to be sent:", payload);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheets/rejected/${user.Data.empId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Rejection successful:", result);

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Timesheet rejected successfully",
          life: 3000,
        });
        setSelectAllState({}); // Reset all checkboxes to unchecked
        fetchTimesheetsData();
      } else {
        console.error("Failed to reject:", response.statusText);

        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to reject timesheet",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error rejecting selected:", error);

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while rejecting timesheet",
        life: 3000,
      });
    } finally {
      setRejectDialogVisible(false);
      setRejectionReason("");
    }
  };

  const filteredData = formattedData.filter(
    (rowData) =>
      rowData.week ===
      `${format(selectedWeekStart, "dd/MM/yyyy")} - ${format(
        selectedWeekEnd,
        "dd/MM/yyyy"
      )}`
  );

  const groupedData = filteredData.reduce((acc, rowData) => {
    const { name, project } = rowData;
    const key = `${name}-${project}`;

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(rowData);
    return acc;
  }, {});

  return (
    <div
      className="w-full"
      style={{
        height: "calc(100vh - 190px)",
        boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
        border: "1px solid rgba(128, 128, 128, 0.211)",
        overflow: "scroll",
      }}
    >
      {/* Week Selection and Buttons */}
      <Toast ref={toast} />
      <div
        className="w-full flex"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <label
            style={{
              marginRight: "10px",
              fontWeight: "bold",
              color: "#00000080",
              fontSize: "18px",
            }}
          >
            Select Week:
          </label>
          <Button
            icon="pi pi-minus"
            onClick={decrementWeek}
            style={{
              marginRight: "5px",
              backgroundColor: "#29295FFF",
              border: "1px solid #29295FFF",
            }}
            disabled={weekNumber === 1}
          />
          {format(selectedWeekStart, "dd/MM/yyyy")} -{" "}
          {format(selectedWeekEnd, "dd/MM/yyyy")}
          <Button
            icon="pi pi-plus"
            onClick={incrementWeek}
            style={{
              marginLeft: "5px",
              backgroundColor: "#29295FFF",
              border: "1px solid #29295FFF",
            }}
            disabled={weekNumber === totalWeeks}
          />
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            gap: "25px",
            justifyContent: "flex-end",
            paddingRight: "50px",
          }}
        >
          {!showFullLayout && (
            <>
              <Button
                label="Reject"
                severity="danger"
                raised
                onClick={handleRejectButtonClick}
                disabled={selectedApprovals.length === 0}
              />
              <Button
                label="Approve"
                severity="success"
                raised
                onClick={handleApproveSelected}
                disabled={selectedApprovals.length === 0}
              />
            </>
          )}
          <Dropdown
            value={selectedProject}
            options={projectOptions}
            onChange={(e) => setSelectedProject(e.value)}
            placeholder="Select Project"
            style={{ width: "8vw" }}
          />
        </div>
      </div>
      {/* Week Number and Pagination */}
      <div>
        {/* Timesheet Data Table */}
        <div
          style={{
            border: "1px solid #EBEBEB",
            padding: "25px",
            boxShadow: "1px 2px 4px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
            padding: "20px",
            margin: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                width: "20%",
                fontWeight: "bold",
                color: "#373D3F",
                textAlign: "center",
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "20%",
                fontWeight: "bold",
                color: "#373D3F",
                textAlign: "center",
              }}
            >
              Project
            </div>
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
              (days, index) => (
                <div
                  key={index}
                  style={{
                    width: "8.88%",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#0069FFFF",
                  }}
                >
                  {days}
                </div>
              )
            )}
            <div
              style={{
                width: "8.88%",
                textAlign: "center",
                fontWeight: "bold",
                color: "#373D3F",
              }}
            >
              Total
            </div>
            {!showFullLayout && (
              <div
                style={{
                  width: "8.88%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#373D3F",
                }}
              >
                Approve
              </div>
            )}
          </div>
          <Divider />

          <div style={{ paddingTop: "15px" }}>
            <div
              style={{
                display: "flex",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: "40%",
                  fontWeight: "bold",
                  color: "#373D3F",
                  textAlign: "center",
                }}
              ></div>

              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "jan"].map(
                (days, index) => (
                  <div
                    key={index}
                    style={{
                      width: "8.88%",
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "#0069FFFF",
                    }}
                  ></div>
                )
              )}

              {!showFullLayout && (
                <div
                  style={{
                    width: "9.99%",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#373D3F",
                  }}
                >
                  <Checkbox
                    type="checkbox"
                    checked={
                      Object.values(selectAllState).length > 0 &&
                      Object.values(selectAllState).every(Boolean)
                    }
                    onChange={(e) =>
                      handleSelectAllChange("all", e.target.checked)
                    }
                  />
                </div>
              )}
            </div>

            {Object.keys(groupedData).length === 0 ? (
              <p>No timesheet found</p>
            ) : (
              Object.keys(groupedData).map((key, index) => {
                const [employeeName, projectName] = key.split("-");
                const projectData = groupedData[key];

                const totalHoursPerDay = projectData.map(
                  (rowData) => rowData.total
                );

                const displayHoursPerDay = daysOfWeek.map((day, dayIndex) => {
                  const dayKey = format(day, "dd/MM/yyyy");

                  // Sum WorkHours for this day across all tasks in the week, while handling non-numeric values
                  const totalWorkHoursForDay = projectData.reduce(
                    (sum, rowData) => {
                      const workHours = rowData[dayKey]?.WorkHours;

                      if (
                        workHours !== undefined &&
                        workHours !== null &&
                        !isNaN(workHours)
                      ) {
                        // Parse workHours as a number and add it to the sum
                        return sum + parseFloat(workHours);
                      } else {
                        // If workHours is alphanumeric (e.g., "leave", "sick") or invalid, do not include it in the sum
                        return sum;
                      }
                    },
                    0
                  );

                  let workHoursForDay =
                    projectData
                      .map((rowData) => rowData[dayKey]?.WorkHours)
                      .find(
                        (workHours) =>
                          workHours && isNaN(workHours) && workHours !== "-"
                      ) || totalWorkHoursForDay;

                  // If workHoursForDay is 0, display "-"
                  if (workHoursForDay === 0) {
                    workHoursForDay = "-";
                  }
                  return workHoursForDay;
                });

                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                      border: "1px solid #EBEBEB",
                      alignItems: "center",

                      transition: "transform 0.3s ease",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                      padding: "10px",
                    }}
                    // onClick={() => {
                    //   console.log(index, "clicked on an employee")
                    //   const weekRange = `${format(selectedWeekStart, "dd-MM-yyyy")} - ${format(selectedWeekEnd, "dd-MM-yyyy")}`;
                    //   // Navigate to the URL with "All" as the project
                    //   const projectToPass = selectedProject === "all" ? timesheetsData[index]?.project?._id : selectedProject
                    //   navigate(`/timesheets/employee/${employeeName}/${weekRange}/${projectToPass}`);
                    // }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          width: "20%",
                          textAlign: "center",
                          color: "#00000080",
                          fontWeight: "bolder",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          console.log(index, "clicked on an employee");

                          const weekRange = `${format(
                            selectedWeekStart,
                            "dd-MM-yyyy"
                          )} - ${format(selectedWeekEnd, "dd-MM-yyyy")}`;

                          // Use the correct project ID based on the displayed data
                          const projectToPass =
                            selectedProject === "all"
                              ? projectData[0]?.projectId // Use the project ID from the grouped data
                              : selectedProject;

                          navigate(
                            `/timesheets/employee/${employeeName}/${weekRange}/${projectToPass}`
                          );
                        }}
                      >
                        {employeeName}
                      </div>
                      <div
                        style={{
                          width: "20%",
                          textAlign: "center",
                          color: "#00000080",
                          fontWeight: "bolder",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          console.log(index, "clicked on an employee");

                          const weekRange = `${format(
                            selectedWeekStart,
                            "dd-MM-yyyy"
                          )} - ${format(selectedWeekEnd, "dd-MM-yyyy")}`;

                          // Use the correct project ID based on the displayed data
                          const projectToPass =
                            selectedProject === "all"
                              ? projectData[0]?.projectId // Use the project ID from the grouped data
                              : selectedProject;

                          navigate(
                            `/timesheets/employee/${employeeName}/${weekRange}/${projectToPass}`
                          );
                        }}
                      >
                        {projectName}
                      </div>
                      {daysOfWeek.map((day, index) => {
                        // const isSaturday = format(day, "eeee") === "Saturday";
                        // const isSunday = format(day, "eeee") === "Sunday";
                        const workHours = displayHoursPerDay[index];

                        return (
                          <div
                            key={index}
                            style={{
                              width: "8.88%",
                              textAlign: "center",
                              fontWeight: "bold",
                              cursor: "pointer",
                              color: isNaN(workHours) ? "red" : "black", // Red for non-numeric values
                            }}
                            onClick={() => {
                              console.log(index, "clicked on an employee");

                              const weekRange = `${format(
                                selectedWeekStart,
                                "dd-MM-yyyy"
                              )} - ${format(selectedWeekEnd, "dd-MM-yyyy")}`;

                              // Use the correct project ID based on the displayed data
                              const projectToPass =
                                selectedProject === "all"
                                  ? projectData[0]?.projectId // Use the project ID from the grouped data
                                  : selectedProject;

                              navigate(
                                `/timesheets/employee/${employeeName}/${weekRange}/${projectToPass}`
                              );
                            }}
                          >
                            {workHours}
                          </div>
                        );
                      })}

                      <div
                        style={{
                          width: "8.88%",
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {totalHoursPerDay.reduce(
                          (acc, hours) => acc + hours,
                          0
                        )}
                      </div>
                      {!showFullLayout && (
                        <div
                          style={{
                            width: "8.88%",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          <Checkbox
                            // style={{width:'100%'}}
                            checked={selectAllState[key] || false}
                            onChange={(e) => {
                              handleSelectAllChange(key, e.target.checked);

                              // If checkbox is checked, trigger approval logic
                              // if (e.target.checked) {
                              //   handleApproveSelected();
                              // }
                            }}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      )}
                    </div>
                    {expandedEmployees.includes(key) &&
                      projectData.map((rowData, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            marginTop: "10px",
                            border: "1px solid #EBEBEB",
                            borderRadius: "5px",
                            padding: "10px",
                            background: "rgba(0, 0, 0, 0.05)",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <div>
                            <strong>Project:</strong> {rowData.project}
                          </div>
                          <div>
                            <strong>Subproject:</strong> {rowData.subproject}
                          </div>
                          <div>
                            <strong>Task:</strong> {rowData.task}
                          </div>
                          <div>
                            <strong>Week:</strong> {rowData.week}
                          </div>
                          <div>
                            <strong>Total Hours:</strong> {rowData.total}
                          </div>
                        </div>
                      ))}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <Dialog
        header="Reason to Reject"
        style={{ width: "42vw", height: "45vh" }}
        visible={rejectDialogVisible}
        onHide={() => setRejectDialogVisible(false)}
      >
        <div style={{ padding: "20px" }}>
          <div className="p-field pb-2">
            <InputTextarea
              style={{
                width: "80vh",
                height: "20vh",
                border: "1px solid black",
              }}
              value={rejectionReason}
              onChange={handleRejectionReasonChange}
              rows={5}
              cols={30}
              placeholder="Enter rejection reason here..."
            />
            {rejectError && (
              <small style={{ color: "red" }}>{rejectError}</small>
            )}
          </div>
          <div
            className="p-field"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className="p-button-danger"
              style={{ width: "6vw" }}
              icon="pi pi-check"
              label="Reject"
              onClick={handleRejectSelected}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Users;
