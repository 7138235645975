import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext"; // Make sure this path is correct
import logo from "../../assets/logo.png";
import abstract from "../../assets/abstract.png";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
const LoginPage = ({ onLoginSuccess }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // For spinner
  const [authStatus, setAuthStatus] = useState(null);
  const { setUser, user } = useContext(UserContext); // Correctly placed useContext hook here
  const navigate = useNavigate();
  const toast = useRef(null); // Create a ref for Toast

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const parsedEmail = isNaN(email) ? email : Number(email);
  //     const parsedPassword = isNaN(password) ? password : Number(password);

  //     const response = await fetch(
  //       `${process.env.REACT_APP_ADMIN_API_SIGNIN}${process.env.REACT_APP_AUTH_API_ENDPOINT}/login`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           email: parsedEmail,
  //           password: parsedPassword,
  //         }),
  //       }
  //     );
  //     const data = await response.json();

  //     if (response.ok && data.Status === "OK") {
  //       // Save user details in localStorage
  //       localStorage.setItem("user", JSON.stringify(data.Data));
  //       setUser(data); // Store the user data directly

  //       if (data.Data.is_Virgin === 1) {
  //         toast.current.show({
  //           severity: "info",
  //           summary: "Password Reset Required",
  //           detail: "Please reset your password to continue.",
  //           life: 3000,
  //         });
  //         navigate("/resetpassword");
  //       } else {
  //         toast.current.show({
  //           severity: "success",
  //           summary: "Success",
  //           detail: "Login successful!",
  //           life: 3000,
  //         });
  //         // Invoke the onLoginSuccess callback
  //         if (onLoginSuccess) {
  //           onLoginSuccess(); // Notify the parent component of the successful login
  //           navigate("/timesheets");
  //         }
  //       }
  //     } else {
  //       setAuthStatus("failure");
  //       toast.current.show({
  //         severity: "error",
  //         summary: "Error",
  //         detail: "Authentication failed. Please check your credentials.",
  //         life: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     setAuthStatus("failure");
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: "An error occurred during login.",
  //       life: 3000,
  //     });
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner
    try {
      const parsedEmail = isNaN(email) ? email : Number(email);
      const parsedPassword = isNaN(password) ? password : Number(password);

      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_SIGNIN}${process.env.REACT_APP_AUTH_API_ENDPOINT}/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: parsedEmail,
            password: parsedPassword,
          }),
        }
      );
      const data = await response.json();

      setTimeout(() => setLoading(false), 2000); // Stop spinner after 2 seconds

      if (response.ok && data.Status === "OK") {
        localStorage.setItem("user", JSON.stringify(data.Data));
        setUser(data);

        if (data.Data.is_Virgin === 1) {
          toast.current.show({
            severity: "info",
            summary: "Password Reset Required",
            detail: "Please reset your password to continue.",
            life: 1000,
          });
          setTimeout(() => navigate("/resetpassword"), 1000); // Navigate after 1 second
        } else {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Login successful!",
            life: 1000,
          });
          if (onLoginSuccess) {
            setTimeout(() => {
              onLoginSuccess();
              navigate("/timesheets");
            }, 1000);
          }
        }
      } else {
        setAuthStatus("failure");
        setErrorMessage(data.Data || "Authentication failed.");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.Data || "Authentication failed.",
          life: 1000,
        });
      }
    } catch (error) {
      console.error("Login error:", error);
      setAuthStatus("failure");
      setErrorMessage("An error occurred during login.");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred during login.",
        life: 1000,
      });
      setLoading(false);
    }
  };

  const loginFields = [
    {
      label: "Email",
      type: "email",
      id: "email-input",
      name: "email",
      placeholder: "Enter email",
      icon: (
        <i
          className="pi pi-user"
          style={{
            position: "absolute",
            top: "50%",
            left: "1rem",
            transform: "translateY(-50%)",
            color: "#888",
          }}
        ></i>
      ),
    },
    {
      label: "Password",
      type: passwordVisible ? "text" : "password",
      id: "password-input",
      name: "password",
      placeholder: "Enter password",
      icon: (
        <i
          className="pi pi-lock"
          style={{
            position: "absolute",
            top: "50%",
            left: "1rem",
            transform: "translateY(-50%)",
            color: "#888",
          }}
        ></i>
      ),
    },
  ];

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#f5f5f5",
    },
    leftSide: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "2rem",
    },
    rightSide: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      backgroundColor: "#fff",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      borderRadius: "10px",
    },
    form: {
      width: "80%",
      maxWidth: "400px",
      textAlign: "center",
    },
    inputGroup: {
      position: "relative",
      marginBottom: "1rem",
    },
    input: {
      width: "100%",
      padding: "0.75rem 3rem",
      borderRadius: "5px",
      border: "1px solid #ccc",
      fontSize: "1rem",
    },
    toggleIcon: {
      position: "absolute",
      top: "50%",
      right: "1rem",
      transform: "translateY(-50%)",
      color: "#888",
      cursor: "pointer",
    },
    button: {
      width: "100%",
      padding: "0.75rem",
      backgroundColor: "#000066",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "1rem",
    },
    title: {
      fontSize: "1.5rem",
      marginBottom: "1rem",
      fontWeight: "bold",
    },
    subtitle: {
      fontSize: "1rem",
      color: "#888",
      marginBottom: "1.5rem",
    },
  };

  return (
    <>
      <Toast ref={toast} />
      <div style={styles.container}>
        {/* Left Side */}
        <div style={styles.leftSide}>
          <h2 style={{ fontSize: "1.5rem", color: "#000066" }}>
            Where amazing things happen
          </h2>
          <img
            src={abstract} // Replace with actual image path
            alt="Illustration"
            style={{ width: "50%", marginBottom: "1rem" }}
            sizes={20}
          />
        </div>

        {/* Right Side */}
        <div style={styles.rightSide}>
          <form style={styles.form}>
            <img
              src={logo} // Replace with actual logo path
              alt="Logo"
              style={{height:"15vh" }}
              
            />
            <h2 style={styles.title}>IGS Timesheet</h2>

            {loginFields.map((field, index) => (
              <div style={styles.inputGroup} key={index}>
                {field.icon}
                <input
                  type={field.type}
                  id={field.id}
                  name={field.name}
                  placeholder={field.placeholder}
                  style={styles.input}
                  value={field.name === "email" ? email : password} // Conditional value
                  onChange={
                    (e) =>
                      field.name === "email"
                        ? setEmail(e.target.value)
                        : setPassword(e.target.value) // Conditional onChange
                  }
                />
                {field.name === "password" && (
                  <i
                    className={`pi ${
                      passwordVisible ? "pi-eye-slash" : "pi-eye"
                    }`}
                    style={styles.toggleIcon}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  ></i>
                )}
              </div>
            ))}

            <button
              type="submit"
              style={styles.button}
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? (
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#ffffff",
                    verticalAlign: "middle",
                  }}
                  strokeWidth="8"
                  fill="transparent"
                />
              ) : (
                "Login"
              )}
            </button>
            {authStatus === "failure" && errorMessage && (
              <div className={styles.errorMessage}>
                Authentication failed: {errorMessage}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;

// import React, { useState, useContext, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import { UserContext } from "../../context/UserContext"; // Make sure this path is correct
// import logo from "../../assets/logo.png";
// import abstract from "../../assets/abstract.png";
// import { Toast } from "primereact/toast";
// const LoginPage = ({ onLoginSuccess }) => {
//   const [passwordVisible, setPasswordVisible] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [authStatus, setAuthStatus] = useState(null);
//   const { setUser, user } = useContext(UserContext); // Correctly placed useContext hook here
//   const navigate = useNavigate();
//   const toast = useRef(null); // Create a ref for Toast

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     try {
//       const parsedEmail = isNaN(email) ? email : Number(email);
//       const parsedPassword = isNaN(password) ? password : Number(password);
  
//       const response = await fetch(
//         `${process.env.REACT_APP_ADMIN_API_SIGNIN}${process.env.REACT_APP_AUTH_API_ENDPOINT}/login`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             email: parsedEmail,
//             password: parsedPassword,
//           }),
//         }
//       );
//       const data = await response.json();
  
//       if (response.ok && data.Status === "OK") {
  
//         // Save user details in localStorage
//         localStorage.setItem("user", JSON.stringify(data.Data));
//         setUser(data); // Store the user data directly
  
//         if (data.Data.is_Virgin === 1) {
//           toast.current.show({
//             severity: "info",
//             summary: "Password Reset Required",
//             detail: "Please reset your password to continue.",
//             life: 3000,
//           });
//           navigate("/resetpassword");
//         } else {
//           toast.current.show({
//             severity: "success",
//             summary: "Success",
//             detail: "Login successful!",
//             life: 3000,
//           });
//           // Invoke the onLoginSuccess callback
//           if (onLoginSuccess) {
//             onLoginSuccess(); // Notify the parent component of the successful login
//             navigate("/timesheets")
//           }
//         }
//       } else {
//         setAuthStatus("failure");
//         toast.current.show({
//           severity: "error",
//           summary: "Error",
//           detail: "Authentication failed. Please check your credentials.",
//           life: 3000,
//         });
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//       setAuthStatus("failure");
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: "An error occurred during login.",
//         life: 3000,
//       });
//     }
//   };
  

//   const loginFields = [
//     {
//       label: "Email",
//       type: "email",
//       id: "email-input",
//       name: "email",
//       placeholder: "Enter email",
//       icon: (
//         <i
//           className="pi pi-user"
//           style={{
//             position: "absolute",
//             top: "50%",
//             left: "1rem",
//             transform: "translateY(-50%)",
//             color: "#888",
//           }}
//         ></i>
//       ),
//     },
//     {
//       label: "Password",
//       type: passwordVisible ? "text" : "password",
//       id: "password-input",
//       name: "password",
//       placeholder: "Enter password",
//       icon: (
//         <i
//           className="pi pi-lock"
//           style={{
//             position: "absolute",
//             top: "50%",
//             left: "1rem",
//             transform: "translateY(-50%)",
//             color: "#888",
//           }}
//         ></i>
//       ),
//     },
//   ];

//   const styles = {
//     container: {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       height: "100vh",
//       backgroundColor: "#f5f5f5",
//     },
//     leftSide: {
//       flex: 1,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       flexDirection: "column",
//       padding: "2rem",
//     },
//     rightSide: {
//       flex: 1,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       height: "100%",
//       backgroundColor: "#fff",
//       boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//       borderRadius: "10px",
//     },
//     form: {
//       width: "80%",
//       maxWidth: "400px",
//       textAlign: "center",
//     },
//     inputGroup: {
//       position: "relative",
//       marginBottom: "1rem",
//     },
//     input: {
//       width: "100%",
//       padding: "0.75rem 3rem",
//       borderRadius: "5px",
//       border: "1px solid #ccc",
//       fontSize: "1rem",
//     },
//     toggleIcon: {
//       position: "absolute",
//       top: "50%",
//       right: "1rem",
//       transform: "translateY(-50%)",
//       color: "#888",
//       cursor: "pointer",
//     },
//     button: {
//       width: "100%",
//       padding: "0.75rem",
//       backgroundColor: "#000066",
//       color: "#fff",
//       border: "none",
//       borderRadius: "5px",
//       cursor: "pointer",
//       fontSize: "1rem",
//     },
//     title: {
//       fontSize: "1.5rem",
//       marginBottom: "1rem",
//       fontWeight: "bold",
//     },
//     subtitle: {
//       fontSize: "1rem",
//       color: "#888",
//       marginBottom: "1.5rem",
//     },
//   };

//   return (
//     <>
//       <Toast ref={toast} />
//       <div style={styles.container}>
//         {/* Left Side */}
//         <div style={styles.leftSide}>
//           <h2 style={{ fontSize: "1.5rem", color: "#000066" }}>
//             Where amazing things happen
//           </h2>
//           <img
//             src={abstract} // Replace with actual image path
//             alt="Illustration"
//             style={{ width: "50%", marginBottom: "1rem" }}
//             sizes={20}

//           />
//         </div>

//         {/* Right Side */}
//         <div style={styles.rightSide}>
//           <form style={styles.form}>
//             <img
//               src={logo} // Replace with actual logo path
//               alt="Logo"
//               style={{ margin: "0 auto", marginBottom: "1rem" }}
//             />
//             <h2 style={styles.title}>IGS Timesheet</h2>

//             {loginFields.map((field, index) => (
//               <div style={styles.inputGroup} key={index}>
//                 {field.icon}
//                 <input
//                   type={field.type}
//                   id={field.id}
//                   name={field.name}
//                   placeholder={field.placeholder}
//                   style={styles.input}
//                   value={field.name === "email" ? email : password} // Conditional value
//                   onChange={
//                     (e) =>
//                       field.name === "email"
//                         ? setEmail(e.target.value)
//                         : setPassword(e.target.value) // Conditional onChange
//                   }
//                 />
//                 {field.name === "password" && (
//                   <i
//                     className={`pi ${
//                       passwordVisible ? "pi-eye-slash" : "pi-eye"
//                     }`}
//                     style={styles.toggleIcon}
//                     onClick={() => setPasswordVisible(!passwordVisible)}
//                   ></i>
//                 )}
//               </div>
//             ))}

//             <button type="submit" style={styles.button} onClick={handleLogin}>
//               Login
//             </button>
//             {authStatus === "failure" && (
//               <div className={styles.errorMessage}>
//                 Authentication failed. Please check your credentials.
//               </div>
//             )}
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default LoginPage;
// import React, { useState, useContext, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import { UserContext } from "../../context/UserContext"; // Make sure this path is correct
// import styles from "./Login.module.css";
// import logo from "../../assets/logo.png";
// import abstract from "../../assets/abstract.png";
// import { Toast } from "primereact/toast";

// const Login = ({ onLoginSuccess }) => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [authStatus, setAuthStatus] = useState(null);
//   const [signIn, setSignIn] = useState(false);
//   const { setUser, user } = useContext(UserContext); // Correctly placed useContext hook here
//   const navigate = useNavigate();
//   const toast = useRef(null); // Create a ref for Toast


  
//   const handleLogin = async () => {
//     try {
//       // Convert email and password to appropriate types
//       const parsedEmail = isNaN(email) ? email : Number(email);
//       const parsedPassword = isNaN(password) ? password : Number(password);

//       const response = await fetch(
//         `${process.env.REACT_APP_ADMIN_API_SIGNIN}${process.env.REACT_APP_AUTH_API_ENDPOINT}/login`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ email: parsedEmail, password: parsedPassword }),
//         }
//       );

//       const data = await response.json();

//       if (response.ok && data.Status === "OK") {
//         // Save user details in localStorage
//         localStorage.setItem("user", JSON.stringify(data.Data));

//         // Update the context with user data
//         setUser(data);

//         // Save user details in localStorage
//         localStorage.setItem("user", JSON.stringify(data));

//         // Show success message
//         toast.current.show({ severity: "success", summary: "Success", detail: "Login successful!", life: 3000 });

//         // Notify parent component of login success
//         onLoginSuccess();

//         // Navigate to the projects page
//         navigate("/timesheets");
//       } else {
//         setAuthStatus("failure");
//         // Show error message
//         toast.current.show({ severity: "error", summary: "Error", detail: "Authentication failed. Please check your credentials.", life: 3000 });
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//       setAuthStatus("failure");
//       // Show error message
//       toast.current.show({ severity: "error", summary: "Error", detail: "An error occurred during login.", life: 3000 });
//     }
//   };

//   const handleSignIn = async () => {
//     console.log("bob is here", user?.Data);
    
//     try {
//       // Accessing empId from user context data
//       const empId = user?.Data?.empId;

//       if (!empId) {
//         console.error("Employee ID not found.");
//         setAuthStatus("failure");
//         // Show error message
//         toast.current.show({ severity: "error", summary: "Error", detail: "Employee ID not found.", life: 3000 });
//         return;
//       }

//       const response = await fetch(
//         `${process.env.REACT_APP_ADMIN_API_SIGNIN1}${process.env.REACT_APP_ADMIN_API_SIGNIN_ENDPOINT}/employee/change-password/${empId}`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ newPassword: password }),
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         console.log("Password updated successfully:", data);
//         setAuthStatus("success");
//         // Show success message
//         toast.current.show({ severity: "success", summary: "Success", detail: "Password updated successfully.", life: 3000 });
//       } else {
//         setAuthStatus("failure");
//         console.error("Failed to update password");
//         // Show error message
//         toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update password.", life: 3000 });
//       }
//     } catch (error) {
//       console.error("Error during password update:", error);
//       setAuthStatus("failure");
//       // Show error message
//       toast.current.show({ severity: "error", summary: "Error", detail: "An error occurred during password update.", life: 3000 });
//     }
//   };
 

//   return (
//     <div className={styles.container}>
//       <Toast ref={toast} /> {/* Toast component placed here */}
//       <div className={styles.leftPanel}>
//         <div className={styles.text}>Where amazing things happen</div>
//         <div className={styles.illustrations}>
//           <div className={styles.illustrationItem}>
//             <img src={abstract} alt="Graph" />
//           </div>
//         </div>
//       </div>
//       <div className={styles.rightPanel}>
//         <div className={styles.logo}>
//           <img src={logo} alt="Logo" />
//           <h1>IGS TimeSheets</h1>
//         </div>
//         {!signIn && (
//           <div className={styles.form}>
//             <div className={styles.inputGroup}>
//               <label>Email</label>
//               <input
//                 type="email"
//                 placeholder="Enter email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </div>
//             <div className={styles.inputGroup}>
//               <label>Password</label>
//               <input
//                 type="password"
//                 placeholder="Enter password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//             <div className={styles.forgotPassword}>
//               <a onClick={() => setSignIn(true)} style={{ cursor: "pointer" }}>
//                 Sign In
//               </a>
//             </div>
//             <button className={styles.loginButton} onClick={handleLogin}>
//               Login
//             </button>
//             {authStatus === "failure" && (
//               <div className={styles.errorMessage}>
//                 Authentication failed. Please check your credentials.
//               </div>
//             )}
//           </div>
//         )}
//         {signIn && (
//           <div className={styles.form}>
//             <div className={styles.inputGroup}>
//               <label>Email</label>
//               <input
//                 type="email"
//                 placeholder="Enter email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </div>
//             <div className={styles.inputGroup}>
//               <label>New Password</label>
//               <input
//                 type="password"
//                 placeholder="Enter New Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//             <div className={styles.forgotPassword}>
//               <a onClick={() => setSignIn(false)} style={{ cursor: "pointer" }}>
//                 Log In
//               </a>
//             </div>
//             <button className={styles.loginButton} onClick={() => { handleSignIn() }}>
//               Sign In
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Login;
