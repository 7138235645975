import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import logo from "../../../assets/IGS.png";
import DownloadBenchButton from "./DownloadBench";
import { ProgressSpinner } from "primereact/progressspinner";
import { NavLink } from "react-router-dom";
import * as XLSX from "xlsx";
import { FaDownload } from "react-icons/fa";
const BenchStatusTable = () => {
  // const [benchData, setBenchData] = useState([]);
  const [selectedBenchStatus, setSelectedBenchStatus] = useState("all"); // Default "All" selection
  const [filteredData, setFilteredData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(""); // Global search state

  const [benchData, setBenchData] = useState({
    onBench: { employees: [] },
    notOnBench: { employees: [] },
  });
  const [activeStatus, setActiveStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/bench-status`
      );
      const data = await response.json();
      setBenchData(data);

      // Default to "All" selection when data is loaded
      handleBenchStatusChange({ value: "all" }, data);
    };
    fetchData();
  }, []);

  const handleBenchStatusChange = (status) => {
    if (!benchData.onBench || !benchData.notOnBench) {
      console.error("Bench data is not available");
      return;
    }
    setSelectedBenchStatus(status);
    setActiveStatus(status); // Update active status on click

    if (status === "onBench") {
      setFilteredData(benchData.onBench.employees || []);
    } else if (status === "ml") {
      setFilteredData(benchData.ml.employees || []);
    } else if (status === "lop") {
      setFilteredData(benchData.lop.employees || []);
    } else if (status === "management") {
      setFilteredData(benchData.management.employees || []);
    } else if (status === "notOnBench") {
      setFilteredData(benchData.notOnBench.employees || []);
    } else if (status === "all") {
      const allEmployees = [
        ...(benchData.onBench?.employees || []),
        ...(benchData.management?.employees || []),
        ...(benchData.ml?.employees || []),
        ...(benchData.lop?.employees || []),
        ...(benchData.notOnBench?.employees || []),
  
      ];
      setFilteredData(allEmployees);
    }
  };

  useEffect(() => {
    if (benchData.onBench && benchData.notOnBench) {
      handleBenchStatusChange("all"); // Default to show all data when benchData is available
    }
  }, [benchData]);

  // Handle global search
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };
  const formatSalary = (salary) => {
    // Round the salary to the nearest whole number
    salary = Math.round(salary);
    // Split the whole part and format it with commas
    let wholePart = salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return wholePart; // Return only the whole part without decimals
  };

  const globalFilteredData = filteredData.filter((employee) => {
    const searchValue = globalFilter.toLowerCase();

    // Check if the employee details match the search value
    const matchesEmployeeDetails =
      employee.empId?.toLowerCase().includes(searchValue) ||
      "" ||
      employee.name?.toLowerCase().includes(searchValue) ||
      "" ||
      employee.designation?.toLowerCase().includes(searchValue) ||
      "";

    // Check if the project name matches the search value
    const matchesProjectName = employee.projects?.some(
      (project) =>
        project.projectName?.toLowerCase().includes(searchValue) || ""
    );

    // Check if "Bench" matches the search value
    const isOnBench = benchData.onBench.employees.some(
      (emp) => emp._id === employee._id
    );
    const matchesBenchStatus = isOnBench && "bench".includes(searchValue);

    // Return true if any of the conditions match
    return matchesEmployeeDetails || matchesProjectName || matchesBenchStatus;
  });

  // Dynamic style for bench status column
  const benchStatusTemplate = (rowData) => {
    // Check if the employee belongs to each category
    const isOnBench = benchData.onBench.employees.some(
      (emp) => emp.empId === rowData.empId
    );
    const isNotOnBench = benchData.notOnBench.employees.some(
      (emp) => emp.empId === rowData.empId
    );
    const isMl = benchData.ml.employees.some(
      (emp) => emp.empId === rowData.empId
    );
    const isManagement =
      !isMl &&
      benchData.management.employees.some((emp) => emp.empId === rowData.empId);
    const isLop = benchData.lop.employees.some((emp) => emp.empId === rowData.empId)
    let color = "#ffffff"; // Default color
    let statusText = "Unknown"; // Default status

    // Set status and color based on the category
    if (isOnBench) {
      color = "#4a0202"; // On bench color
      statusText = "Bench";
    } else if (isMl) {
      color = "#9b59b6"; // ML color
      statusText = "ML";
    } else if (isManagement) {
      color = "#1f618d"; // Management color
      statusText = "Management";
    } else if (isLop) {
      color = "#000205";
      statusText = "LOP"
    } else if (isNotOnBench) {
      color = "#145a32"; // Not on bench color
      statusText = "Allocated";
    }

    return (
      <span style={{ color: color, fontWeight: "bold" }}>{statusText}</span>
    );
  };

  const projectsTemplate = (rowData) => {
    const isOnBench = benchData.onBench.employees.some(
      (emp) => emp.empId === rowData.empId
    );
    const isMl = benchData.ml.employees.some(
      (emp) => emp.empId === rowData.empId
    );
    const isManagement =
      !isMl &&
      benchData.management.employees.some((emp) => emp.empId === rowData.empId);
    const isLop = benchData.lop.employees.some(
      (emp) => emp.empId === rowData.empId
    );

    if (isOnBench) {
      return <span style={{ fontWeight: "bold" }}>Bench</span>;
    } else if (isMl) {
      return <span style={{ fontWeight: "bold" }}>ML</span>;
    } else if (isManagement) {
      return <span style={{ fontWeight: "bold" }}>Management</span>;
    } else if (isLop) {
      return <span style={{ fontWeight: "bold" }}>LOP</span>;
    } else {
      // For not-on-bench employees and not in ML or management, display project names
      const projectNames =
        rowData.projects?.map((project) => project.projectName).join(", ") ||
        "N/A";
      return <span style={{ fontWeight: "bold" }}>{projectNames}</span>;
    }
  };

  useEffect(() => {
    // Simulate a loading delay for demo purposes
    const loadProjects = setTimeout(() => {
      setLoading(false);
    }, 1000); // Set to your actual loading time

    return () => clearTimeout(loadProjects);
  }, []);
  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "22px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
        Reports
      </p>
    </div>
  );
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );
  const formatSalary1 = (salary) => {
    // Round the salary to the nearest whole number
    salary = Math.round(salary);
  
    // Convert the salary to a string
    let salaryStr = salary.toString();
  
    // Apply Indian format: last three digits remain as they are, and the rest are grouped in pairs
    const lastThree = salaryStr.slice(-3); // Extract the last three digits
    const rest = salaryStr.slice(0, -3); // Extract the remaining digits
  
    const formattedSalary =
      rest.length > 0
        ? rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
        : lastThree; // Add commas in pairs for the rest
  
    return formattedSalary;
  };


  const downloadExcel = () => {
    // Determine the data to download based on activeStatus
    let dataToDownload = [];
    if (activeStatus === "all") {
      dataToDownload = [
        ...benchData.onBench.employees,
        ...benchData.ml.employees,
        ...benchData.management.employees,
        ...benchData.lop.employees,
        ...benchData.notOnBench.employees,
       
      ];
    } else if (activeStatus === "onBench") {
      dataToDownload = benchData.onBench.employees;
    } else if (activeStatus === "notOnBench") {
      dataToDownload = benchData.notOnBench.employees;
    } else if (activeStatus === "ml") {
      dataToDownload = benchData.ml.employees;
    } else if (activeStatus === "management") {
      dataToDownload = benchData.management.employees;
    } else if (activeStatus === "lop") {
      dataToDownload = benchData.lop.employees;
    }

    // Map data to include only necessary columns
    const formattedData = dataToDownload.map((employee) => ({
      "Employee ID": employee.empId,
      Name: employee.name,
      Designation: employee.designation,
      Salary: employee.salary ? `₹ ${formatSalary1(employee.salary)}` : "N/A",
      Status:
      activeStatus === "all"
      ? determineStatus(employee) // Helper function for status
      : activeStatus === "notOnBench"
      ? "Allocated" // Explicitly handle "notOnBench"
      : activeStatus === "onBench"
      ? "Bench" // Explicitly handle "onBench"
      : activeStatus.charAt(0).toUpperCase() + activeStatus.slice(1),
      "Allocated Projects":
        employee.projects?.map((project) => project.projectName).join(", ") ||
        "N/A",
    }));

    // Create a worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Trigger a download
    XLSX.writeFile(workbook, `${activeStatus}_resources.xlsx`);
  };

  // Helper function to determine the status of an employee
  const determineStatus = (employee) => {
    if (
      benchData.onBench.employees.some((emp) => emp.empId === employee.empId)
    ) {
      return "Bench";
    }
    if (benchData.ml.employees.some((emp) => emp.empId === employee.empId)) {
      return "ML";
    }
    if (
      benchData.management.employees.some((emp) => emp.empId === employee.empId)
    ) {
      return "Management";
    }
    if (
      benchData.notOnBench.employees.some((emp) => emp.empId === employee.empId)
    ) {
      return "Allocated";
    }
    return "LOP";
  };
  return (
    <div>
      <div className="card">
        <Menubar start={start} end={end} />
      </div>
      <div
        style={{
          backgroundColor: "#f8f9fa",
          height: "calc(100vh - 140px)",
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          marginTop: "10px",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        {/* Flex container for dropdown and global search */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1em",
            alignItems: "center",
          }}
        >
          {/* Left section for dropdown and global search */}
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            {/* Global search input */}
            <span className="p-input-icon-left">
              <InputText
                value={globalFilter}
                onChange={onGlobalFilterChange}
                placeholder="Search"
                className="mb-2"
              />
            </span>
            <div>
              <NavLink to="/benchtype">
                <button
                  style={{
                    backgroundColor: "#29295F",
                    color: "white",
                    border: "1px solid #29295F",
                    padding: "12px 20px",
                    marginBottom: "5px",
                    cursor: "pointer",
                    borderRadius: "5px",
                  }}
                >
                  Others
                </button>
              </NavLink>
            </div>
          </div>

          {/* Right section for Bench and Not On Bench counts */}
          <div
            style={{
              display: "flex",
              gap: "30px",
              alignItems: "flex-end",
              marginBottom: "2vh",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                color:
                  activeStatus === "all"
                    ? "#5d6d7e" // Gray for "all"
                    : activeStatus === "onBench"
                    ? "#4a0202" // Red for "onBench"
                    : activeStatus === "notOnBench"
                    ? "#145a32"
                    : activeStatus === "ml"
                    ? "#9b59b6"
                    : activeStatus === "management"
                    ? "#1f618d"
                    : "#000205", // Green for "notOnBench"
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              Total:{" "}
              {(() => {
                let selectedEmployees = [];
                if (benchData) {
                  if (activeStatus === "all") {
                    selectedEmployees = [
                      ...(benchData.onBench?.employees || []),
                      ...(benchData.notOnBench?.employees || []),
                      ...(benchData.ml?.employees || []),
                      ...(benchData.management?.employees || []),
                    ];
                  } else if (activeStatus === "onBench") {
                    selectedEmployees = benchData.onBench?.employees || [];
                  } else if (activeStatus === "notOnBench") {
                    selectedEmployees = benchData.notOnBench?.employees || [];
                  } else if (activeStatus === "ml") {
                    selectedEmployees = benchData.ml?.employees || [];
                  } else if (activeStatus === "management") {
                    selectedEmployees = benchData.management?.employees || [];
                  } else if (activeStatus === "lop") {
                    selectedEmployees = benchData.lop?.employees || [];
                  }
                }

                // If the status is "lop", return 0.00
                if (activeStatus === "lop") {
                  return "₹ 0.00";
                }

                // Calculate the total salary
                const totalSalary = selectedEmployees.reduce((total, emp) => {
                  const salary = parseFloat(emp.salary); // Ensure salary is a number
                  if (!isNaN(salary) && salary > 0) {
                    return total + salary;
                  } else {
                    console.warn("Invalid salary for employee:", emp); // Debug invalid salary
                    return total;
                  }
                }, 0);

                return `₹ ${formatSalary1(totalSalary)}`; // Format the total salary
              })()}
            </div>

            <div
              style={{
                fontWeight: "bold",
                color: "#5d6d7e",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                boxShadow:
                  activeStatus === "all"
                    ? "4px 4px 12px rgba(0, 0, 0, 0.2)"
                    : "2px 2px 8px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => handleBenchStatusChange("all")}
            >
              Resources:{" "}
              {benchData.onBench.employees.length +
                benchData.notOnBench.employees.length +
                benchData.ml?.employees?.length +
                benchData.management?.employees?.length +
                benchData.lop?.employees?.length}
            </div>

            <div
              style={{
                fontWeight: "bold",
                color: "#145a32",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                boxShadow:
                  activeStatus === "notOnBench"
                    ? "4px 4px 12px rgba(0, 0, 0, 0.2)"
                    : "2px 2px 8px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer", // Add pointer cursor
              }}
              onClick={() => handleBenchStatusChange("notOnBench")} // Trigger filter for "Not on Bench"
            >
              Allocated: {benchData.notOnBench.employees.length}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "#4a0202",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                boxShadow:
                  activeStatus === "onBench"
                    ? "4px 4px 12px rgba(0, 0, 0, 0.2)"
                    : "2px 2px 8px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer", // Add pointer cursor
              }}
              onClick={() => handleBenchStatusChange("onBench")} // Trigger filter for "On Bench"
            >
              Bench: {benchData.onBench.employees.length}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "#9b59b6",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                boxShadow:
                  activeStatus === "ml"
                    ? "4px 4px 12px rgba(0, 0, 0, 0.2)"
                    : "2px 2px 8px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer", // Add pointer cursor
              }}
              onClick={() => handleBenchStatusChange("ml")} // Trigger filter for "On Bench"
            >
              ML: {benchData.ml?.employees.length}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "#1f618d",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                boxShadow:
                  activeStatus === "management"
                    ? "4px 4px 12px rgba(0, 0, 0, 0.2)"
                    : "2px 2px 8px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer", // Add pointer cursor
              }}
              onClick={() => handleBenchStatusChange("management")} // Trigger filter for "On Bench"
            >
              Management: {benchData.management?.employees.length}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "#000205",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                boxShadow:
                  activeStatus === "lop"
                    ? "4px 4px 12px rgba(0, 0, 0, 0.2)"
                    : "2px 2px 8px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer", // Add pointer cursor
              }}
              onClick={() => handleBenchStatusChange("lop")} // Trigger filter for "On Bench"
            >
              LOP: {benchData.lop?.employees.length}
            </div>
            <div style={{ marginBottom: "4px" }}>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={downloadExcel}
              >
                <FaDownload size={30} color="green" />
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 300px)",
            }}
          >
            <ProgressSpinner />
          </div>
        ) : (
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            {globalFilteredData.length > 0 && (
              <DataTable value={globalFilteredData} responsiveLayout="scroll">
                <Column field="empId" header="Employee ID" />
                <Column field="name" header="Name" />
                <Column field="designation" header="Designation" />
                <Column
                  header="Salary"
                  body={(rowData) => (
                    <span>₹ {formatSalary1(rowData.salary)}</span>
                  )}
                />
                <Column header="Status" body={benchStatusTemplate} />
                <Column header="Allocated Projects" body={projectsTemplate} />
              </DataTable>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BenchStatusTable;
