import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UserContext } from "../../../context/UserContext";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import logo from "../../../assets/IGS.png";
import { TbArrowBack } from "react-icons/tb";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";

const EmployeeDetails = () => {
  const { employeeName, weekRange, selectedProject } = useParams();
  const [timesheetData, setTimesheetData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true); // Spinner state
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheets/pending-request/${user.Data.empId}`
        );
        const data = await response.json();

        // Filter the timesheet data that matches the employeeName, weekRange, and selectedProject
        const filteredData = data.timesheets.filter(
          (timesheet) =>
            timesheet.employeeName === employeeName &&
            timesheet.week.replace(/\//g, "-") === weekRange &&
            timesheet.project._id === selectedProject
        );

        if (filteredData.length > 0) {
          setProjectName(filteredData[0].project.name);
          setStatus(filteredData[0].status); // Set the status from the filtered data
        }

        // Simulate a delay of 3 seconds
        setTimeout(() => {
          setTimesheetData(filteredData);
          setLoading(false); // Hide spinner after loading
        }, 1000);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Hide spinner in case of error
      }
    };

    fetchData();
  }, [employeeName, weekRange, selectedProject, user.Data.empId]);

  useEffect(() => {
    // Filter data based on the status after it's set
    if (status) {
      setTimesheetData((prevData) =>
        prevData.filter((timesheet) => timesheet.status === status)
      );
    }
  }, [status]);

  console.log(timesheetData, projectName);
  const start = (
    <button
      className="rounded-full m-2 hover:bg-gray-200"
      style={{ height: "40px", width: "40px" }}
      onClick={() => navigate(-1)}
    >
      <TbArrowBack />
    </button>
  );
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  return (
    <div>
      <Menubar start={start} end={end} />
      <div
        style={{
          backgroundColor: "#f8f9fa",
          height: "calc(100vh - 132px)",
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          marginTop: "10px",
          overflow: "scroll",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <h2
            style={{
              fontWeight: "bold",
              color: "#FFC300",
              fontSize: "18px",
              margin: "20px",
            }}
          >
            <span style={{ color: "#29295FFF" }}>Status:</span> {status}
          </h2>
          {projectName && (
            <h2
              style={{
                fontWeight: "bold",
                color: "#00000080",
                fontSize: "18px",
                margin: "20px",
              }}
            >
              <span style={{ color: "#29295FFF" }}>Project:</span> {projectName}
            </h2>
          )}
          <h2
            style={{
              fontWeight: "bold",
              color: "#00000080",
              fontSize: "18px",
              margin: "20px",
            }}
          >
            <span style={{ color: "#29295FFF" }}>Name:</span> {employeeName}
          </h2>
          <h2
            style={{
              fontWeight: "bold",
              color: "#00000080",
              fontSize: "18px",
              margin: "20px",
            }}
          >
            <span style={{ color: "#29295FFF" }}>Week Range:</span> {weekRange}
          </h2>
        </div>
        <Divider />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 300px)",
            }}
          >
            <ProgressSpinner />
          </div>
        ) : (
          <div style={{ flexGrow: 1, margin: "20px", overflow: "hidden" }}>
            {" "}
            {/* Flex grow makes this div take the remaining space */}
            {timesheetData.length > 0 ? (
              <div
                style={{
                  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "5px",
                  overflow: "auto",
                  maxHeight: "calc(100vh - 200px)",
                }}
              >
                <DataTable
                  value={timesheetData}
                  tableStyle={{ minWidth: "50rem" }}
                >
                  <Column
                    field="subproject.name"
                    header="Sub Project"
                    style={{ width: "15%" }}
                  ></Column>
                  <Column
                    field="task.name"
                    header="Task"
                    style={{ width: "15%" }}
                  ></Column>

                  {/* Day columns for the week */}
                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                    (day) => (
                      <Column
                        key={day}
                        header={day}
                        body={(rowData) => {
                          const entry =
                            rowData.entries[0].hours[day.toLowerCase()];
                          let value =
                            entry?.time === "-" || entry?.time === ""
                              ? "-"
                              : entry?.time;

                          // Replace 0 with "Sun" for Sunday and "Sat" for Saturday
                          if (day === "Sun" && value === "0") {
                            value = "Sun";
                          } else if (day === "Sat" && value === "0") {
                            value = "Sat";
                          }

                          // Check if the value is non-numeric
                          const isNonNumeric = isNaN(value);

                          return (
                            <span
                              style={{ color: isNonNumeric ? "red" : "black" }}
                            >
                              {value}
                            </span>
                          );
                        }}
                        style={{ width: "8.75%" }}
                        headerStyle={{ color: "#0069FFFF" }}
                      />
                    )
                  )}

                  {/* Total Hours Column */}
                  <Column
                    field="totalHours"
                    header="Total"
                    style={{ width: "8.75%", fontWeight: "bold" }}
                  />
                </DataTable>
              </div>
            ) : (
              <p>No data available</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeDetails;




// import React, { useContext, useEffect, useState } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { UserContext } from "../../../context/UserContext";
// import { Button } from "primereact/button";
// import { Image } from "primereact/image";
// import { Menubar } from "primereact/menubar";
// import logo from "../../../assets/IGS.png";
// import { TbArrowBack } from "react-icons/tb";
// import { Divider } from "primereact/divider";

// const EmployeeDetails = () => {
//   const { employeeName, weekRange, selectedProject } = useParams();
//   const [timesheetData, setTimesheetData] = useState([]);
//   const [projectName, setProjectName] = useState("");
//   const { user } = useContext(UserContext);
//   const navigate = useNavigate();
//   const [status, setStatus] = useState("");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheets/pending-request/${user.Data.empId}`
//         );
//         const data = await response.json();

//         // Filter the timesheet data that matches the employeeName, weekRange, and selectedProject
//         const filteredData = data.timesheets.filter(
//           (timesheet) =>
//             timesheet.employeeName === employeeName &&
//             timesheet.week.replace(/\//g, "-") === weekRange &&
//             timesheet.project._id === selectedProject
//         );

//         if (filteredData.length > 0) {
//           setProjectName(filteredData[0].project.name);
//           setStatus(filteredData[0].status); // Set the status from the filtered data
//         }
//         setTimesheetData(filteredData);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [employeeName, weekRange, selectedProject, user.Data.empId]);

//   useEffect(() => {
//     // Filter data based on the status after it's set
//     if (status) {
//       setTimesheetData((prevData) =>
//         prevData.filter((timesheet) => timesheet.status === status)
//       );
//     }
//   }, [status]);



//   console.log(timesheetData, projectName);
//   const start = (
//     <button
//       className="rounded-full m-2 hover:bg-gray-200"
//       style={{ height: "40px", width: "40px" }}
//       onClick={() => navigate(-1)}
//     >
//       <TbArrowBack />
//     </button>
//   );
//   const end = (
//     <div style={{ paddingRight: "15px" }}>
//       <Image src={logo} height="60px" width="60px" />
//     </div>
//   );

//   return (
//     <div>
//       <Menubar start={start} end={end} />
//       <div
//         style={{
//           backgroundColor: "#f8f9fa",
//           height: "calc(100vh - 132px)",
//           boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
//           border: "1px solid rgba(128, 128, 128, 0.211)",
//           marginTop: "10px",
//           overflow: "scroll",
//           position: "relative",
//         }}
//       >
//         <div style={{ display: "flex", justifyContent: "space-around" }}>
//           <h2
//             style={{
//               fontWeight: "bold",
//               color: "#FFC300",
//               fontSize: "18px",
//               margin: "20px",
//             }}
//           >
//             <span style={{ color: "#29295FFF" }}>Status:</span> {status}
//           </h2>
//           {projectName && (
//             <h2
//               style={{
//                 fontWeight: "bold",
//                 color: "#00000080",
//                 fontSize: "18px",
//                 margin: "20px",
//               }}
//             >
//               <span style={{ color: "#29295FFF" }}>Project:</span> {projectName}
//             </h2>
//           )}
//           <h2
//             style={{
//               fontWeight: "bold",
//               color: "#00000080",
//               fontSize: "18px",
//               margin: "20px",
//             }}
//           >
//             <span style={{ color: "#29295FFF" }}>Name:</span> {employeeName}
//           </h2>
//           <h2
//             style={{
//               fontWeight: "bold",
//               color: "#00000080",
//               fontSize: "18px",
//               margin: "20px",
//             }}
//           >
//             <span style={{ color: "#29295FFF" }}>Week Range:</span> {weekRange}
//           </h2>
//         </div>
//         <Divider />
//         <div style={{ flexGrow: 1, margin: "20px", overflow: "hidden" }}>
//           {" "}
//           {/* Flex grow makes this div take the remaining space */}
//           {timesheetData.length > 0 ? (
//             <div
//               style={{
//                 boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
//                 borderRadius: "5px",
//                 overflow: "auto",
//                 maxHeight: "calc(100vh - 200px)",
//               }}
//             >
//               <DataTable
//                 value={timesheetData}
//                 tableStyle={{ minWidth: "50rem" }}
//               >
//                 <Column
//                   field="subproject.name"
//                   header="Sub Project"
//                   style={{ width: "15%" }}
//                 ></Column>
//                 <Column
//                   field="task.name"
//                   header="Task"
//                   style={{ width: "15%" }}
//                 ></Column>

//                 {/* Day columns for the week */}
//                 {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
//                   (day) => (
//                     <Column
//                       key={day}
//                       header={day}
//                       body={(rowData) => {
//                         const entry =
//                           rowData.entries[0].hours[day.toLowerCase()];
//                         let value =
//                           entry?.time === "-" || entry?.time === ""
//                             ? "0"
//                             : entry?.time;

//                         // Replace 0 with "Sun" for Sunday and "Sat" for Saturday
//                         if (day === "Sun" && value === "0") {
//                           value = "Sun";
//                         } else if (day === "Sat" && value === "0") {
//                           value = "Sat";
//                         }

//                         // Check if the value is non-numeric
//                         const isNonNumeric = isNaN(value);

//                         return (
//                           <span
//                             style={{ color: isNonNumeric ? "red" : "black" }}
//                           >
//                             {value}
//                           </span>
//                         );
//                       }}
//                       style={{ width: "8.75%" }}
//                       headerStyle={{ color: "#0069FFFF" }}
//                     />
//                   )
//                 )}

//                 {/* Total Hours Column */}
//                 <Column 
//                 field="totalHours" 
//                 header="Total" 
//                 style={{ width: "8.75%", fontWeight: "bold" }} 
//             />
//               </DataTable>
//             </div>
//           ) : (
//             <p>No data available</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EmployeeDetails;
