import React, { useState, useEffect } from "react";
import "./billing.css";
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import logo from "../../../../assets/IGS.png";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import DownloadExcel from "../DownloadExcel/DownloadAttendance";
const ProjectTable = () => {
  const [data, setData] = useState([]); // To hold all fetched data
  const [filteredData, setFilteredData] = useState([]); // For filtering
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProject, setSelectedProject] = useState("All");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Fetching timesheet data from the API
  // useEffect(() => {
  //   fetch(
  //     `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/getTimesheetData`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Ensure the data is an array
  //       if (Array.isArray(data)) {
  //         setData(data);

  //         // Find the latest month
  //         const months = data.map((item) => item.month);
  //         const latestMonth = getLatestMonth(months);

  //         // Set the default selected project to "All" and the latest month
  //         setSelectedProject("All");
  //         setSelectedMonth(latestMonth);

  //         // Filter data immediately with the latest month and project "All"
  //         filterData(searchQuery, "All", latestMonth);
  //       } else {
  //         console.error("Expected array but got:", data);
  //         setData([]); // Set data to an empty array if it's not in the expected format
  //       }
  //     })
  //     .catch((error) => console.error("Error fetching timesheet data:", error));
  // }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/getTimesheetData`
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setData(data);
          const months = data.map((item) => item.month);
          const latestMonth = getLatestMonth(months);
          setSelectedProject("All");
          setSelectedMonth(latestMonth);
          filterData(searchQuery, "All", latestMonth);
        } else {
          console.error("Expected array but got:", data);
          setData([]);
        }
      })
      .catch((error) => console.error("Error fetching timesheet data:", error));
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      filterData(searchQuery, selectedProject, selectedMonth);
    }
  }, [data, selectedProject, selectedMonth]);

  // Helper function to get the latest month
  const getLatestMonth = (months) => {
    return months.sort((a, b) => new Date(b) - new Date(a))[0]; // Get latest month
  };

  // Handle the search input
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    filterData(query, selectedProject, selectedMonth);
  };

  // Handle project filter
  const handleProjectFilter = (event) => {
    const project = event.target.value;
    setSelectedProject(project);
    filterData(searchQuery, project, selectedMonth);
  };

  // Handle month filter
  const handleMonthFilter = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    filterData(searchQuery, selectedProject, month);
  };

  // Filter data based on search query, project, and month
  const filterData = (query, project, month) => {
    const filtered = data
      .map((entry) => {
        // Ensure projects and employees are defined and are arrays
        const projects = Array.isArray(entry.projects) ? entry.projects : [];
        const employees = Array.isArray(entry.employees) ? entry.employees : [];

        // Filter projects within each entry based on selected project
        const filteredProjects = projects.filter((p) => {
          return project === "All" || p.projectName === project;
        });

        // Create a regex for case-insensitive search
        const regex = new RegExp(query, "i");

        // Check if any project name matches the search query
        const matchingProjects = filteredProjects.filter((p) =>
          regex.test(p.projectName)
        );

        // Check if any employee name matches the search query
        const matchingEmployees = employees.filter((employee) =>
          regex.test(employee.name)
        );

        // If there are matching projects or employees, return the entry
        if (matchingProjects.length > 0 || matchingEmployees.length > 0) {
          return {
            ...entry,
            projects: matchingProjects, // Only include the filtered projects
            employees: matchingEmployees, // Optionally include filtered employees
          };
        }

        return null; // Return null if no projects or employees match
      })
      .filter(
        (entry) => entry !== null && (month === "" || entry.month === month)
      ); // Filter out null entries and ensure the month matches

    setFilteredData(filtered); // Update filtered data in state
  };

  // Get unique projects and months for dropdowns
  const uniqueProjects = [
    "All",
    ...new Set(
      data.flatMap((item) =>
        item.projects.map((project) => project.projectName)
      )
    ),
  ];
  // Exclude "All" and only show actual months
  const uniqueMonths = [...new Set(data.map((item) => item.month))];

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/leaveList`
    ) // Update the URL if needed
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setLeaveData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching leave data: {error.message}</p>;

  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "20px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
        Attendence Report
      </p>
    </div>
  );

  const end = (
    <div className="pr-4">
      <Image src={logo} height="50px" width="50px" />
    </div>
  );
  return (
    <div>
      <div className="card">
        <Menubar start={start} end={end} />
      </div>
      <div
        style={{
          backgroundColor: "#f8f9fa",
          height: "calc(100vh - 140px)",
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          marginTop: "10px",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        <div
          className="filter-container flex items-center"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="flex"
            style={{
              width: "70%",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "5px",
              gap: "20px",
            }}
          >
            <div
              style={{
                width: "25%",
                display: "flex",
                gap: "10px",
                justifyContent: "flex-start",
                // paddingRight: "50px",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "bold",
                  color: "#00000080",
                  fontSize: "18px",
                }}
              >
                Project:
              </label>
              <Dropdown
                id="projectDropdown"
                value={selectedProject}
                options={uniqueProjects.map((project) => ({
                  label: project,
                  value: project,
                }))}
                onChange={handleProjectFilter}
                placeholder="Select a Project"
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                width: "25%",
                display: "flex",
                gap: "10px",
                justifyContent: "flex-start",
                // paddingRight: "50px",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontWeight: "bold",
                  color: "#00000080",
                  fontSize: "18px",
                }}
              >
                Month:
              </label>
              <Dropdown
                id="monthDropdown"
                value={selectedMonth}
                options={uniqueMonths.map((month) => ({
                  label: month,
                  value: month,
                }))}
                onChange={handleMonthFilter}
                placeholder="Select a Month"
                style={{ width: "100%" }}
              />
            </div>

            <div className="flex items-center" style={{ width: "20%" }}>
              <InputText
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Resource Name"
                className="p-inputtext"
              />
            </div>
          </div>

          <div
            className="flex"
            style={{
              width: "40%",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "20px",
              gap: "10px",
            }}
          >
            <div>
              <DownloadExcel
                data={filteredData}
                fileName="Attendance Report"
                sheetName="Attendance Report"
              />
            </div>
          </div>
        </div>

        {/* Display filtered data */}
        <div
          style={{
            backgroundColor: "#f8f9fa",
            boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
            border: "1px solid rgba(128, 128, 128, 0.211)",
            marginTop: "10px",
            overflowX: "scroll", // Ensures horizontal scrolling
            position: "relative",
            marginBottom: "10px",
          }}
        >
          {filteredData.length === 0 ? (
            <p>No matching records found</p>
          ) : (
            filteredData.map((entry, index) => {
              // Filter out weekends (Sat and Sun) and "HOL" from the working days
              // const workingDaysCount = entry.days.filter(
              //   (day, dayIndex) =>
              //     day !== "Sat" &&
              //     day !== "Sun" &&
              //     entry.projects.every((project) =>
              //       project.employees.every(
              //         (employee) => employee.hours[dayIndex] !== "HOL"
              //       )
              //     )
              // ).length;
              const workingDaysCount = entry.days.filter((_, dayIndex) =>
                entry.projects.every((project) =>
                  project.employees.every(
                    (employee) => employee.hours[dayIndex] !== "HOL"
                  )
                )
              ).length;
              

              return (
                <div key={index}>
                  <table>
                    <thead>
                      <tr>
                        <th>{entry.month}</th>
                        <th>Date</th>
                        {entry.dates.map((date, dateIndex) => (
                          <th key={dateIndex}>{date}</th>
                        ))}
                        <th colSpan={3} style={{ backgroundColor: "yellow" }}>
                          Working Days: {workingDaysCount}
                        </th>
                      </tr>
                      <tr>
                        <th>Project</th>
                        <th>Day</th>
                        {entry.days.map((day, dayIndex) => (
                          <th key={dayIndex}>{day}</th>
                        ))}
                        <th style={{ backgroundColor: "red" }}>Leave</th>
                        <th style={{ backgroundColor: "#52be80" }}>Present</th>
                        <th style={{ backgroundColor: "#52be80" }}>
                          Total Hour
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {entry.projects.map((project, projectIndex) =>
                        project.employees.map((employee, empIndex) => {
                          let leaveCount = 0;
                          let presentCount = 0;
                          let totalHours = 0; // Initialize total hours for each employee

                          employee.hours.forEach((hour) => {
                            // Calculate leave count
                            if (hour === "E") {
                              leaveCount += 1;
                            } else if (hour === "EH") {
                              leaveCount += 0.5;
                            } else if (hour === "S" || hour === "C" || hour === "BL" || hour === "PL" || hour === "CL" || hour === "LP") {
                              leaveCount += 1;
                            } else if (hour === "SH" || hour === "CH") {
                              leaveCount += 0.5;
                            } else if (hour === "HOL") {
                              leaveCount += 0; // HOL does not add to leave count
                            }

                            // Calculate present count
                            // Calculate present count
                            if (hour === "EH" || hour === "SH" || hour === "CH") {
                              presentCount += 0.5;
                            } else if (typeof hour === "number") {
                              if (hour > 0 && hour < 5) {
                                presentCount += 0.5;
                              } else if (hour > 5) {
                                presentCount += 1;
                              }
                            }
                            

                            // Accumulate total hours (excluding "HOL" and other non-working hours)
                            if (typeof hour === "number") {
                              totalHours += hour;
                            } else if (
                              hour === "EH" ||
                              hour === "SH" ||
                              hour === "CH"
                            ) {
                              totalHours += 4; // Half-day counts as 4 hours
                            }
                          });

                          return (
                            <tr key={`${projectIndex}-${empIndex}`}>
                              <td>{project.projectName}</td>
                              <td>{employee.name}</td>
                              {entry.days.map((day, dayIndex) => {
                                const hour = employee.hours[dayIndex];

                                let bgColor;
                                let displayValue = hour;

                                // if (day === "Sat") {
                                //   bgColor = "lightgray";
                                //   displayValue = "Sat";
                                // } else if (day === "Sun") {
                                //   bgColor = "lightgray";
                                //   displayValue = "Sun";
                                // } else
                                 if (hour === "C" || hour === "CH") {
                                  bgColor = "peachpuff";
                                } else if (hour === "S" || hour === "SH") {
                                  bgColor = "red";
                                } else if (hour === "E" || hour === "EH") {
                                  bgColor = "lightblue";
                                } else if (hour === "HOL") {
                                  bgColor = "#f8a53a";
                                } else if (hour === "BL") {
                                  bgColor = "#e67300";
                                } else if (hour === "LP") {
                                  bgColor = "yellow";
                                } else if (hour === "PL") {
                                  bgColor = "#0066cc";
                                } else if (hour === "CL") {
                                  bgColor = "#a3a375";
                                } else {
                                  bgColor = "white";
                                }

                                return (
                                  <td
                                    key={dayIndex}
                                    style={{ backgroundColor: bgColor }}
                                  >
                                    {displayValue}
                                  </td>
                                );
                              })}
                              <td>{leaveCount || "0"}</td>
                              <td>{presentCount || "0"}</td>
                              <td>{totalHours || "0"} Hrs</td>{" "}
                              {/* Display total hours */}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              );
            })
          )}
        </div>

        <div style={{ padding: "10px" }}>
          <table className="leave-table">
            <thead>
              <tr>
                <th>Leave Type</th>
                <th>Code</th>
                <th>Half Day Code</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {leaveData.length === 0 ? (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No leaves available
                  </td>
                </tr>
              ) : (
                leaveData.map((leave, index) => {
                  // Define the color based on leaveType
                  let color = "";

                  switch (leave.leaveType) {
                    case "Sick Leave":
                      color = "red";
                      break;
                    case "Earned Leave":
                      color = "lightblue";
                      break;
                    case "Company Holiday":
                      color = "#e67300";
                      break;
                    case "Bereavement Leave":
                      color = "beige";
                      break;
                    case "Comp Off":
                      color = "peachpuff";
                      break;
                    case "Bereavement Leave":
                      color = "#e67300";
                      break;
                    case "Leave Without Pay":
                      color = "yellow";
                      break;
                      case "Paternity Leave":
                        color = "#0066cc";
                        break;
                        case "Covid Leave":
                          color = "#a3a375";
                          break;
                    default:
                      color = "white";
                  }

                  return (
                    <tr key={index} style={{ backgroundColor: color }}>
                      <td>{leave.leaveType}</td>
                      <td>{leave.code}</td>
                      <td>{leave.halfDayCode || "-"}</td>
                      <td>{leave.remarks || "-"}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProjectTable;
{
  /* {filteredData.length === 0 ? (
            <p>No matching records found</p>
          ) : (
            filteredData.map((entry, index) => (
              <div key={index}>
                <table>
                  <thead>
                    <tr>
                      <th>{entry.month}</th>
                      <th>Date</th>
                      {entry.dates.map((date, dateIndex) => (
                        <th key={dateIndex}>{date}</th>
                      ))}
                      <th colSpan={2}>Working Days: </th>{" "}
                   
                    </tr>
                    <tr>
                      <th>Project</th>
                      <th>Day</th>
                      {entry.days.map((day, dayIndex) => (
                        <th key={dayIndex}>{day}</th>
                      ))}
                      <th>Leave</th> 
                      <th>Present</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {entry.projects.map((project, projectIndex) =>
                      project.employees.map((employee, empIndex) => (
                        <tr key={`${projectIndex}-${empIndex}`}>
                          <td>{project.projectName}</td>
                          <td>{employee.name}</td>
                          {entry.days.map((day, dayIndex) => {
                            const date = entry.dates[dayIndex]; // Get corresponding date
                            const hour = employee.hours[dayIndex]; // Get corresponding hour

                            // Check if it's Saturday or Sunday and apply gray background
                            if (day === "Sat") {
                              return (
                                <td
                                  key={dayIndex}
                                  style={{ backgroundColor: "lightgray" }}
                                >
                                  Sat
                                </td>
                              );
                            } else if (day === "Sun") {
                              return (
                                <td
                                  key={dayIndex}
                                  style={{ backgroundColor: "lightgray" }}
                                >
                                  Sun
                                </td>
                              );
                            } else {
                              return <td key={dayIndex}>{hour}</td>; // For other days, display hour
                            }
                          })}
                          <td>{employee.leave || "N/A"}</td>
                          <td>{employee.present || "N/A"}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            ))
          )} */
}
{
  /* {filteredData.length === 0 ? (
            <p>No matching records found</p>
          ) : (
            filteredData.map((entry, index) => (
              <div key={index}>
                <table>
                  <thead>
                    <tr>
                      <th>{entry.month}</th>
                      <th>Date</th>
                      {entry.dates.map((date, dateIndex) => (
                        <th key={dateIndex}>{date}</th>
                      ))}
                    </tr>
                    <tr>
                      <th>Project</th>
                      <th>Day</th>
                      {entry.days.map((day, dayIndex) => (
                        <th key={dayIndex}>{day}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {entry.employees.map((employee, empIndex) => (
                      <tr key={empIndex}>
                        <td>{entry.projectName}</td>
                        <td>{employee.name}</td>
                        {employee.hours.map((hour, hourIndex) => (
                          <td key={hourIndex}>{hour}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))
          )} */
}
{
  /* {filteredData.length === 0 ? (
  <p>No matching records found</p>
) : (
  filteredData.map((entry, index) => (
    <div key={index}>
      <table>
        <thead>
          <tr>
            <th>{entry.month}</th>
            <th>Date</th>
            {entry.dates && entry.dates.length > 0 ? (
              entry.dates.map((date, dateIndex) => (
                <th key={dateIndex}>{date}</th>
              ))
            ) : (
              <th>No dates available</th>
            )}
          </tr>
          <tr>
            <th>Project</th>
            <th>Day</th>
            {entry.days && entry.days.length > 0 ? (
              entry.days.map((day, dayIndex) => (
                <th key={dayIndex}>{day}</th>
              ))
            ) : (
              <th>No days available</th>
            )}
          </tr>
        </thead>
        <tbody>
          {entry.employees.map((employee, empIndex) => (
            <tr key={empIndex}>
              <td>{entry.projectName}</td>
              <td>{employee.name}</td>
              {employee.hours && employee.hours.length > 0 ? (
                employee.hours.map((hour, hourIndex) => (
                  <td key={hourIndex}>{hour}</td>
                ))
              ) : (
                <td>No hours recorded</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ))
)} */
}

// import React from "react";
// import "./billing.css"; // Optional CSS for styling

// const ProjectTable = () => {
//   const data = {
//     month: "October",
//     days: [
//       "Tue",
//       "Wed",
//       "Thu",
//       "Fri",
//       "Sat",
//       "Sun",
//       "Mon",
//       "Tue",
//       "Wed",
//       "Thu",
//       "Fri",
//       "Sat",
//       "Sun",
//       "Mon",
//       "Tue",
//       "Wed",
//       "Thu",
//       "Fri",
//       "Sat",
//       "Sun",
//       "Mon",
//       "Tue",
//       "Wed",
//       "Thu",
//       "Fri",
//       "Sat",
//       "Sun",
//       "Mon",
//       "Tue",
//       "Wed",
//     ],
//     dates: Array.from({ length: 30 }, (_, i) => i + 1),
//     projectName: "qw",
//     employees: [
//       {
//         name: "Parthasarathy Pandiyan",
//         hours: [
//           8, 8, 8, 8, 8, 8, 'S', 8, 8, 8, 8, 8, 8, 8,'SH', 8, 8, 8, 8, 8, 8, 8, 8,
//           8, 8, 8, 8, 8, 8, 8,
//         ],
//       },
//       {
//         name: "Tanisha Joseline Cutinha",
//         hours: [
//           7, 6, 7, 8, 4, 6, 8, 7, 8, 8, 'E', 5, 5, 8, 7, 6, 'HOL', 7, 4, 6, 8, 6, 7,
//           7, 4, 6, 5, 8, 7, 8,
//         ],
//       },
//       {
//         name: "Harsha Kumar H C",
//         hours: [
//           6, 7, 8, 7, 3, 5, 6, 8, 7, 8, 8, 'EH', 3, 8, 7, 8, 7, 6, 8, 7, 8, 8, 6,
//           8, 8, 7, 3, 8, 6, 7,
//         ],
//       },
//     ],
//   };

//   return (
//     <div className="table-container">
//       <h2>{data.month} Project Hours Table</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>{data.month} </th>
//             <th>Date</th>
//             {data.dates.map((date, index) => (
//               <th key={index}>{date}</th>
//             ))}
//           </tr>
//           <tr>
//             <th>Project</th>
//             <th>Day</th>
//             {data.days.map((day, index) => (
//               <th key={index}>{day}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {data.employees.map((employee, empIndex) => (
//             <tr key={empIndex}>
//               <td>{data.projectName}</td>
//               <td>{employee.name}</td>
//               {employee.hours.map((hour, hourIndex) => (
//                 <td key={hourIndex}>{hour}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default ProjectTable;

// import React, { useState } from 'react';
// import { InputText } from 'primereact/inputtext';
// import { Button } from 'primereact/button';

// const ProjectReportCalculator = ({ visible, onHide }) => {
//     const [totalHoursWorked, setTotalHoursWorked] = useState(0);
//     const [salary, setSalary] = useState(0);
//     const [billingRate, setBillingRate] = useState(0);
//     const [results, setResults] = useState(null);

//     const totalDaysInMonth = 30; // Assume 30 days in a month for simplicity

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         // Calculate daysWorked
//         const daysWorked = Math.min(totalHoursWorked / 8, totalDaysInMonth);

//         // Calculate CTC for Days Worked
//         const ctcForDaysWorked = (salary / totalDaysInMonth) * daysWorked;

//         // Calculate Net Billing for Days Worked
//         const netBilling = (billingRate / totalDaysInMonth) * daysWorked;

//         // Calculate Margin
//         const margin = netBilling - ctcForDaysWorked;

//         // Calculate Margin Percentage
//         const marginPercentage = (margin / ctcForDaysWorked) * 100;

//         // Set the results
//         setResults({
//             daysWorked: daysWorked.toFixed(2),
//             ctcForDaysWorked: ctcForDaysWorked.toFixed(2),
//             netBilling: netBilling.toFixed(2),
//             margin: margin.toFixed(2),
//             marginPercentage: marginPercentage.toFixed(2),
//         });
//     };

//     return (
//         <div style={{ display: 'flex', padding: '10px' }}>
//             {/* Left Side: Input Fields */}
//             <div style={{ width: '50%', paddingRight: '20px' }}>
//                 <form onSubmit={handleSubmit}>
//                     <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
//                         <label style={{ width: '40%' }}>Total Hours Worked:</label>
//                         <InputText
//                             value={totalHoursWorked}
//                             onChange={(e) => setTotalHoursWorked(e.target.value)}
//                             type="number"
//                             required
//                             style={{ flex: 1, marginLeft: '10px' }}
//                         />
//                     </div>

//                     <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
//                         <label style={{ width: '40%' }}>Salary:</label>
//                         <InputText
//                             value={salary}
//                             onChange={(e) => setSalary(e.target.value)}
//                             type="number"
//                             required
//                             style={{ flex: 1, marginLeft: '10px' }}
//                         />
//                     </div>

//                     <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
//                         <label style={{ width: '40%' }}>Billing Rate:</label>
//                         <InputText
//                             value={billingRate}
//                             onChange={(e) => setBillingRate(e.target.value)}
//                             type="number"
//                             required
//                             style={{ flex: 1, marginLeft: '10px' }}
//                         />
//                     </div>

//                     <Button
//                         type="submit"
//                         label="Calculate"
//                         className="p-button-success"
//                         style={{ marginTop: '20px', width: '100%' }}
//                     />
//                 </form>
//             </div>

//             {/* Right Side: Results or Message */}
//             <div style={{ width: '50%', paddingLeft: '20px', borderLeft: '1px solid #ccc', position: 'relative', height: '100%' }}>
//                 {results ? (
//                     <div>
//                         <h3 style={{textAlign:'center'}}>Results</h3>
//                         <p><strong>Days Worked:</strong> {results.daysWorked}</p>
//                         <p><strong>CTC for Days Worked:</strong> ₹{results.ctcForDaysWorked}</p>
//                         <p><strong>Net Billing:</strong> ₹{results.netBilling}</p>
//                         <p><strong>Margin:</strong> ₹{results.margin}</p>
//                         <p><strong>Margin Percentage:</strong> {results.marginPercentage}%</p>
//                     </div>
//                 ) : (
//                     <div style={{

//                         textAlign:'center',
//                         color: '#00000080',
//                     }}>
//                         <h3>Calculate Your Billing Report</h3>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ProjectReportCalculator;
