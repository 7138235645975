import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Image } from "primereact/image";
import logo from "../../../../assets/IGS.png";
import { TbArrowBack } from "react-icons/tb";
import { Menubar } from "primereact/menubar";
import { FaDownload } from "react-icons/fa";
import * as XLSX from "xlsx";
export default function AddNew() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [maternityLeave, setMaternityLeave] = useState([]);
  const [lossOfPay, setLossOfPay] = useState([]);
  const [management, setManagement] = useState([]);
  const [benchemployee, setBenchEmployee] = useState([]);
  const [selectedRole, setSelectedRole] = useState("ml"); // Default to "lop"
  const [selectedEmployees, setSelectedEmployees] = useState({
    lop: [],
    ml: [],
    management: [],
  });
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [totalSalary, setTotalSalary] = useState(0);

  // Fetch the bench status data on component mount
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/bench-status`
    )
      .then((response) => response.json())
      .then((data) => {
        const maternityLeaveData = data.ml?.employees || [];
        const lossOfPayData = data.lop?.employees || [];
        const managementData = data.management?.employees || [];
        const benchEmp = (data.onBench?.employees || []).concat(
          data?.notOnBench?.employees || []
        );

        setBenchEmployee(benchEmp);
        setMaternityLeave(maternityLeaveData);
        setLossOfPay(lossOfPayData);
        setManagement(managementData);

        setFilteredEmployees(maternityLeaveData);
        calculateTotalSalary(maternityLeaveData);
      })
      .catch((error) => console.error("Error fetching filtered data:", error));
  }, []);

  // Calculate total salary for the selected employees
  const calculateTotalSalary = (employees) => {
    const total = employees.reduce(
      (sum, employee) => sum + (employee.salary || 0),
      0
    );
    setTotalSalary(total);
  };

  // Handle role selection
// Handle role selection
const handleRoleSelect = (role) => {
    setSelectedRole(role);
    switch (role) {
      case "lop":
        setFilteredEmployees(lossOfPay);
        setTotalSalary(0); // Explicitly set total salary to 0 for "lop"
        break;
      case "ml":
        setFilteredEmployees(maternityLeave);
        calculateTotalSalary(maternityLeave);
        break;
      case "management":
        setFilteredEmployees(management);
        calculateTotalSalary(management);
        break;
      default:
        setFilteredEmployees([]);
        setTotalSalary(0); // Default to 0 if no role is selected
    }
  };
  

  const exportToExcel = () => {
    const roleName =
      selectedRole === "lop"
        ? "Loss of Pay"
        : selectedRole === "ml"
        ? "Maternity Leave"
        : "Management";

    // Map the filtered employees data to include "leave type"
    const excelData = filteredEmployees.map((employee) => ({
      Name: employee.name,
      Designation: employee.designation,
      Salary: employee.salary || "N/A", // Handle null or undefined salary
      Status: roleName,
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, roleName);
    XLSX.writeFile(wb, `${roleName}_employees.xlsx`);
  };

  const handleUserClick = (userEmpId) => {
    const clickedUser = benchemployee.find(
      (employee) => employee.empId === userEmpId
    );

    if (clickedUser) {
      let userAlreadyInRole = false;
      let toastMessage = "";

      // Check if the user is already in any role, including roles other than the current selected role
      if (lossOfPay.some((user) => user.empId === clickedUser.empId)) {
        userAlreadyInRole = true;
        toastMessage = `User ${clickedUser.name} is already in Loss of Pay`;
      } else if (
        maternityLeave.some((user) => user.empId === clickedUser.empId)
      ) {
        userAlreadyInRole = true;
        toastMessage = `User ${clickedUser.name} is already in Maternity Leave`;
      } else if (management.some((user) => user.empId === clickedUser.empId)) {
        userAlreadyInRole = true;
        toastMessage = `User ${clickedUser.name} is already in Management`;
      }

      // Show toast if the user is already assigned to any role
      if (userAlreadyInRole) {
        toast.current.show({
          severity: "warn",
          summary: "User Already Assigned",
          detail: toastMessage,
          life: 3000,
        });
        return;
      }

      // Update selected employees and the corresponding state based on selected role
      setSelectedEmployees((prevState) => {
        return {
          ...prevState,
          [selectedRole]: [
            ...(prevState[selectedRole] || []),
            {
              empId: clickedUser.empId,
              name: clickedUser.name,
              designation: clickedUser.designation,
              salary: clickedUser.salary,
            },
          ],
        };
      });

      // Add the user to the respective list based on the selected role
      if (selectedRole === "lop") {
        setLossOfPay((prevUsers) => [
          ...prevUsers,
          {
            empId: clickedUser.empId,
            name: clickedUser.name,
            designation: clickedUser.designation,
            salary: clickedUser.salary,
          },
        ]);
      } else if (selectedRole === "ml") {
        setMaternityLeave((prevUsers) => [
          ...prevUsers,
          {
            empId: clickedUser.empId,
            name: clickedUser.name,
            designation: clickedUser.designation,
            salary: clickedUser.salary,
          },
        ]);
      } else if (selectedRole === "management") {
        setManagement((prevUsers) => [
          ...prevUsers,
          {
            empId: clickedUser.empId,
            name: clickedUser.name,
            designation: clickedUser.designation,
            salary: clickedUser.salary,
          },
        ]);
      }

      // Set the state to show the submit button
      setIsUserSelected(true);
    }
  };

  const handleSubmit = async () => {
    const roleEmployees = selectedEmployees[selectedRole] || [];
    const employeeIds = roleEmployees.map((employee) => employee.empId);

    const payload = {
      role: selectedRole,
      employeeIds,
    };

    console.log("Payload to be sent:", payload); // Log the payload for debugging

    if (employeeIds.length === 0) {
      toast.current.show({
        severity: "warn",
        summary: "No Employees Selected",
        detail: "Please select employees before submitting.",
        life: 3000, // Duration in milliseconds
      });
      return; // Avoid making the API call if the array is empty
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/saveEmpManagement`,
        // "http://192.168.29.219:5005/api/v1/admin/saveEmpManagement",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Data saved successfully:", result);

        // Display success toast
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Data saved successfully!",
          life: 3000,
        });

        // Wait for 1 second (1000ms) before navigating back
        setTimeout(() => {
          navigate(-1); // This will take the user to the previous page in the history stack
        }, 1000); // 1 second delay
      } else {
        console.error("Error saving data:", response.statusText);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to save data!",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while saving data.",
        life: 3000,
      });
    }
  };

  const handleDelete = async (employeeId) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${
      process.env.REACT_APP_ADMIN_API_ENDPOINT
    }/deleteEmpManagement/${selectedRole.toLowerCase()}`;
    // `http://192.168.29.219:5005/api/v1/admin/deleteEmpManagement/${selectedRole.toLowerCase()}`;
    const payload = {
      employeeIds: [employeeId],
    };

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Remove the employee from the respective role list in state
        if (selectedRole === "lop") {
          setLossOfPay((prevUsers) =>
            prevUsers.filter((user) => user.empId !== employeeId)
          );
        } else if (selectedRole === "ml") {
          setMaternityLeave((prevUsers) =>
            prevUsers.filter((user) => user.empId !== employeeId)
          );
        } else if (selectedRole === "management") {
          setManagement((prevUsers) =>
            prevUsers.filter((user) => user.empId !== employeeId)
          );
        }

        // Show success toast
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Employee deleted successfully",
          life: 3000,
        });
      } else {
        // If there's an error from the API
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete employee",
          life: 3000,
        });
      }
    } catch (error) {
      // Handle any network or unexpected errors
      console.error("Error:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while deleting the employee",
        life: 3000,
      });
    }
  };

  const start = (
    <button
      className="rounded-full m-2 hover:bg-gray-200"
      style={{ height: "40px", width: "40px" }}
      onClick={() => navigate(-1)}
    >
      <TbArrowBack />
    </button>
  );

  const formatSalary = (salary) => {
    // Round the salary to the nearest whole number
    salary = Math.round(salary);
    // Split the whole part and format it with commas
    let wholePart = salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return wholePart; // Return only the whole part without decimals
  };
  const formatSalary1 = (salary) => {
    // Round the salary to the nearest whole number
    salary = Math.round(salary);
  
    // Convert the salary to a string
    let salaryStr = salary.toString();
  
    // Apply Indian format: last three digits remain as they are, and the rest are grouped in pairs
    const lastThree = salaryStr.slice(-3); // Extract the last three digits
    const rest = salaryStr.slice(0, -3); // Extract the remaining digits
  
    const formattedSalary =
      rest.length > 0
        ? rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
        : lastThree; // Add commas in pairs for the rest
  
    return formattedSalary;
  };
  const totalSalaryFormatted =
  selectedRole === "lop" ? "0.00" : `₹ ${formatSalary1(totalSalary)}`;
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );
  return (
    <div>
      <Toast ref={toast} />
      <Menubar start={start} end={end} />
      <div
        style={{
          backgroundColor: "#f8f9fa",
          height: "calc(100vh - 132px)",
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          marginTop: "10px",
          overflow: "scroll",
          position: "relative",
        }}
      >
        <span style={{ display: "flex", justifyContent: "flex-end" }}>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "30px",
              margin: "10px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                color:
                  selectedRole === "ml"
                    ? "#5d6d7e"
                    : selectedRole === "lop"
                    ? "#4a0202"
                    : "#145a32",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              Total:  {totalSalaryFormatted}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "#5d6d7e",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                boxShadow:
                  selectedRole === "ml"
                    ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onClick={() => handleRoleSelect("ml")}
            >
              Maternity Leave: {maternityLeave.length}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "#4a0202",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                boxShadow:
                  selectedRole === "lop"
                    ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onClick={() => handleRoleSelect("lop")}
            >
              Loss of Pay: {lossOfPay.length}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "#145a32",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d9d9d9",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                boxShadow:
                  selectedRole === "management"
                    ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onClick={() => handleRoleSelect("management")}
            >
              Management: {management.length}
            </div>
            <div>
              <button
                onClick={exportToExcel}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <FaDownload size={30} color="green" />
              </button>
            </div>
          </span>
        </span>

        <div>
          <div style={{ padding: "10px" }}>
            <div style={{ display: "flex", marginTop: "25px" }}>
              <div style={{ width: "20%" }}>
                <div
                  style={{
                    background: "#f9f9f9",
                    margin: "10px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
                    padding: "10px",
                    border: "1px solid #EBEBEB",
                    overflowY: "scroll",
                    height: "72vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      width: "100%",
                    }}
                  >
                    <i
                      className="pi pi-search"
                      style={{ fontSize: "1rem" }}
                    ></i>
                    <InputText
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search User"
                      style={{ borderRadius: "5px", width: "80%" }}
                    />
                  </div>

                  {benchemployee
                    .filter(
                      (employee) =>
                        employee.name &&
                        employee.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) // Check if name is defined
                    )
                    .map((employee, index) => (
                      <div
                        style={{
                          margin: "5px",
                          background: "#FFFFFF",
                          padding: "15px",
                          borderRadius: "5px",
                          boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
                          marginTop: "10px",
                          border: "1px solid #EBEBEB",
                          cursor: "pointer",
                        }}
                        key={index}
                        onClick={() => handleUserClick(employee.empId)}
                      >
                        <div style={{ fontWeight: "bold", color: "#373D3F" }}>
                          {employee.name}
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "#00000080",
                            marginTop: "8px",
                            fontSize: "16px",
                          }}
                        >
                          Designation:{" "}
                          <span style={{ color: "green" }}>
                            {employee.designation}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <Divider layout="vertical" />
              <div style={{ width: "80%" }}>
                <div
                  style={{
                    borderRadius: "5px",
                    overflow: "hidden",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                    marginTop: "15px",
                  }}
                >
                  <div>
                    {selectedRole === "lop" && (
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Employee ID</th>
                            <th>Designation</th>
                            <th>Salary</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lossOfPay.length > 0 ? (
                            lossOfPay.map((employee) => (
                              <tr key={employee.empId}>
                                <td>{employee.name}</td>
                                <td>{employee.empId}</td>
                                <td>{employee.designation}</td>
                                <td>₹ {formatSalary(employee.salary)}</td>
                                <td>LOP</td>
                                <td>
                                  <Button
                                    className="p-button-rounded p-button-text"
                                    style={{
                                      color: "#00000080",
                                      fontSize: "20px",
                                    }}
                                    icon="pi pi-trash"
                                    onClick={() => handleDelete(employee.empId)} // Trigger delete on click
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="5"
                                style={{ textAlign: "center", color: "#888" }}
                              >
                                No employees available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}

                    {selectedRole === "ml" && (
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Employee ID</th>
                            <th>Designation</th>
                            <th>Salary</th>
                            <td>Status</td>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {maternityLeave.length > 0 ? (
                            maternityLeave.map((employee) => (
                              <tr key={employee.empId}>
                                <td>{employee.name}</td>
                                <td>{employee.empId}</td>
                                <td>{employee.designation}</td>
                                <td>₹ {formatSalary(employee.salary)}</td>
                                <td>ML</td>
                                <td>
                                  <Button
                                    className="p-button-rounded p-button-text"
                                    style={{
                                      color: "#00000080",
                                      fontSize: "20px",
                                    }}
                                    icon="pi pi-trash"
                                    onClick={() => handleDelete(employee.empId)} // Trigger delete on click
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="5"
                                style={{ textAlign: "center", color: "#888" }}
                              >
                                No employees available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}

                    {selectedRole === "management" && (
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Employee ID</th>
                            <th>Designation</th>
                            <th>Salary</th>
                            <th>Action</th>
                            <th>Ststus</th>
                          </tr>
                        </thead>
                        <tbody>
                          {management.length > 0 ? (
                            management.map((employee) => (
                              <tr key={employee.empId}>
                                <td>{employee.name}</td>
                                <td>{employee.empId}</td>
                                <td>{employee.designation}</td>
                                <td>₹ {formatSalary(employee.salary)}</td>
                                <td>Management</td>
                                <td>
                                  <Button
                                    className="p-button-rounded p-button-text"
                                    style={{
                                      color: "#00000080",
                                      fontSize: "20px",
                                    }}
                                    icon="pi pi-trash"
                                    onClick={() => handleDelete(employee.empId)} // Trigger delete on click
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="5"
                                style={{ textAlign: "center", color: "#888" }}
                              >
                                No employees available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingRight: "30px",
                  }}
                >
                  {isUserSelected && (
                    <Button
                      label="Submit"
                      style={{
                        background: "#29295FFF",
                        border: "1px solid #29295FFF",
                        marginTop: "15px",
                      }}
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
}
