import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import { getTimeSheet } from "../../services/createProjectServices/Services";
import { UserContext } from "../../context/UserContext";
import { Toast } from "primereact/toast";
import {
  format,
  addDays,
  startOfWeek,
  getISOWeeksInYear,
  getYear,
  getISOWeek,
} from "date-fns";
import TimesheetLayout from "./TimesheetLayout";
import CalculateHour from "./CalculateHour";
import Buttons from "./Buttons";

const predefinedStatusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Submitted", value: "pendingRequest" },
  { label: "Rejected", value: "rejected" },
];

export default function TimeSheets() {
  const toast = useRef(null);
  const [rows, setRows] = useState([
    {
      id: new Date().getTime(),
      project: "",
      subProject: "",
      task: "",
      sun: "",
      mon: "",
      tue: "",
      wed: "",
      thu: "",
      fri: "",
      sat: "",
    },
  ]);
  const [projects, setProjects] = useState([]);
  const [subProjects, setSubProjects] = useState([]);
  const [statusOptions, setStatusOptions] = useState(predefinedStatusOptions);
  const [leaves, setLeaves] = useState([]);
  const [leaveSelections, setLeaveSelections] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projectMap, setProjectMap] = useState({});
  const [subProjectMap, setSubProjectMap] = useState({});
  const [taskMap, setTaskMap] = useState({});
  const { user } = useContext(UserContext);
  const currentYear = getYear(new Date());
  const currentWeek = getISOWeek(new Date());
  const [year, setYear] = useState(currentYear); // Default year
  const [weekNumber, setWeekNumber] = useState(currentWeek);
  const [daySum, setDaySum] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [dayTotals, setDayTotals] = useState({
    sun: 0,
    mon: 0,
    tue: 0,
    wed: 0,
    thu: 0,
    fri: 0,
    sat: 0,
  });
  function calculateWeeklySums(data) {
    // Initialize an object to store the sum of each day
    const daySums = {
      sun: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
    };

    // Iterate over each row in the data
    data.forEach((row) => {
      // Function to convert time to hours, considering special cases
      const getHours = (time) => {
        if (time === "SH" || time === "CH" || time === "EH") {
          return 4; // Return 4 hours for special cases
        }
        return parseFloat(time) || 0; // Convert to float or return 0
      };

      // Sum the hours for each day
      daySums.sun += getHours(row.entries[0].hours.sun.time);
      daySums.mon += getHours(row.entries[0].hours.mon.time);
      daySums.tue += getHours(row.entries[0].hours.tue.time);
      daySums.wed += getHours(row.entries[0].hours.wed.time);
      daySums.thu += getHours(row.entries[0].hours.thu.time);
      daySums.fri += getHours(row.entries[0].hours.fri.time);
      daySums.sat += getHours(row.entries[0].hours.sat.time);
    });

    // Convert the day sums object to an array
    const weeklySumsArray = [
      { day: "Sunday", total: daySums.sun },
      { day: "Monday", total: daySums.mon },
      { day: "Tuesday", total: daySums.tue },
      { day: "Wednesday", total: daySums.wed },
      { day: "Thursday", total: daySums.thu },
      { day: "Friday", total: daySums.fri },
      { day: "Saturday", total: daySums.sat },
    ];
    return weeklySumsArray;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTimeSheet(user.Data.empId);
        if (!response || !response.projects || response.projects.length === 0) {
          console.error("No projects found in response");
          return; // Early return if no projects are found
        }

        const data = response.projects;
        setStartDate(data[0].start_Date);
        setEndDate(data[0].end_Date);
        setStatus(response.status);

        // Filter projects based on the selected week
        const filteredProjects = data.filter((project) => {
          const projectStart = new Date(project.start_Date);
          const projectEnd = new Date(project.end_Date);
          return (
            projectStart <= selectedWeekEnd && projectEnd >= selectedWeekStart
          );
        });

        const projectMap = {};
        const subProjectMap = {};
        const taskMap = {};

        filteredProjects.forEach((project) => {
          projectMap[project.projectId] = project.projectName;
          project.subProjects.forEach((subProject) => {
            subProjectMap[subProject.subProjectId] = subProject.subProjectName;
            subProject.tasks.forEach((task) => {
              taskMap[task] = task;
            });
          });
        });

        setProjects(
          filteredProjects.map((project) => ({
            label: project.projectName,
            value: project.projectId,
            subProjects: project.subProjects,
          }))
        );

        setProjectMap(projectMap);
        setSubProjectMap(subProjectMap);
        setTaskMap(taskMap);
      } catch (error) {
        console.error("Error fetching timesheet data:", error.message);
      }
    }

    fetchData();
  }, [user.Data.empId, weekNumber, year]);

  useEffect(() => {
    const apiURL = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/leaveList`;

    const fetchLeaves = async () => {
      try {
        const response = await fetch(apiURL);
        const data = await response.json();

        // Map fetched data to include name, code, and halfDayCode
        const fetchedLeaves = data.map((leave) => ({
          name: leave.leaveType, // Display the leave type
          code: leave.code,
          halfDayCode: leave.halfDayCode,
          remarks: leave.remarks || "", // Optional remarks
        }));

        setLeaves(fetchedLeaves); // Set the leaves state with the fetched data
      } catch (error) {
        console.error("Error fetching leaves:", error);
      }
    };

    fetchLeaves();
  }, []);

  const handleLeaveChange = (e, day) => {
    const { value } = e.target;

    // Update leave selection for the entire day across all rows
    const updatedRows = rows.map((row) => ({
      ...row,
      [day]: value, // Set leave for the selected day (e.g., "sun") for all rows
    }));

    // Update leaveSelections for the entire column (e.g., "sun") for all rows
    const updatedLeaveSelections = rows.map((_, index) => ({
      ...leaveSelections[index],
      [day]: value !== "" ? value : null, // Reset leave if no leave is selected
    }));

    console.log(updatedLeaveSelections);

    setLeaveSelections(updatedLeaveSelections);
    setRows(updatedRows);
  };

  const handleProjectChange = (e, index) => {
    const selectedProject = projects.find(
      (project) => project.value === e.value
    );
    const updatedRows = [...rows];
    updatedRows[index].project = e.value;
    updatedRows[index].subProject =
      selectedProject?.subProjects[0]?.subProjectId || ""; // Set the first subproject automatically
    updatedRows[index].task = ""; // Reset task when project changes
    setRows(updatedRows);

    // Set subprojects when project is selected
    setSubProjects(
      selectedProject?.subProjects.map((subProject) => ({
        label: subProject.subProjectName,
        value: subProject.subProjectId,
        tasks: subProject.tasks,
      })) || []
    );

    // Set the tasks for the automatically selected subproject
    setTasks(
      selectedProject?.subProjects[0]?.tasks.map((task) => ({
        label: task, // Task string as label
        value: task, // Task string as value
      })) || []
    );
  };

  const handleSubProjectChange = (e, index) => {
    const selectedSubProject = subProjects.find(
      (subProject) => subProject.value === e.value
    );
    const updatedRows = [...rows];
    updatedRows[index].subProject = e.value;
    updatedRows[index].task = ""; // Reset task when subproject changes
    setRows(updatedRows);

    // Set tasks as label-value pairs for dropdowns
    setTasks(
      selectedSubProject?.tasks.map((task) => ({
        label: task, // Task string as label
        value: task, // Task string as value
      })) || []
    );
  };

  const handleTaskChange = (e, index) => {
    const updatedRows = [...rows];
    updatedRows[index].task = e.value;
    setRows(updatedRows);
  };

    const handleInputChange = (e, day, index) => {
    const { value } = e.target;
    const decimalRegex = /^\d*\.?\d*$/; // Allows numbers with optional decimals
  
    if (!leaveSelections[index]?.[day]) {
      if (
        decimalRegex.test(value) && // Validates the input format
        Number(value) >= 0 &&       // Ensures value is non-negative
        Number(value) <= 24         // Ensures value does not exceed 24
      ) {
        const updatedRows = [...rows];
        updatedRows[index][day] = value; // Store the value as-is
        setRows(updatedRows);
      } else if (Number(value) > 24) {
        const updatedRows = [...rows];
        updatedRows[index][day] = "24"; // Cap at 24
        setRows(updatedRows);
      } else if (!decimalRegex.test(value)) {
        const updatedRows = [...rows];
        updatedRows[index][day] = ""; // Clear invalid input
        setRows(updatedRows);
      }
    }
  };

  const handleInputChange123 = (e, day, index) => {
    const { value } = e.target;
    const integerRegex = /^[0-9]*$/;

    if (!leaveSelections[index]?.[day]) {
      if (
        integerRegex.test(value) &&
        Number(value) >= 0 &&
        Number(value) <= 24
      ) {
        const updatedRows = [...rows];
        updatedRows[index][day] = value.toString(); // Store as string
        setRows(updatedRows);
      } else if (Number(value) > 24) {
        const updatedRows = [...rows];
        updatedRows[index][day] = "24";
        setRows(updatedRows);
      } else if (!integerRegex.test(value)) {
        const updatedRows = [...rows];
        updatedRows[index][day] = "";
        setRows(updatedRows);
      }
    }
  };

  const totalWeeks = getISOWeeksInYear(new Date(year, 0, 1));
  const currentWeekStart = startOfWeek(new Date(year, 0, 1), {
    weekStartsOn: 0,
  });
  const selectedWeekStart = addDays(currentWeekStart, (weekNumber - 1) * 7);
  const selectedWeekEnd = addDays(selectedWeekStart, 6); // Get the end of the week
  const currentDate = new Date();
  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    daysOfWeek.push(addDays(selectedWeekStart, i));
  }
  const incrementWeek = () => {
    if (weekNumber < totalWeeks) {
      setWeekNumber(weekNumber + 1);
    } else {
      // If it's the last week of the current year, go to the first week of the next year
      setWeekNumber(1);
      setYear(year + 1);
    }

    // Reset leaveSelections for the new week
    const resetLeaveSelections = rows.map(() => ({
      sun: null,
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
    }));

    setLeaveSelections(resetLeaveSelections);
  };

  const decrementWeek = () => {
    if (weekNumber > 1) {
      setWeekNumber(weekNumber - 1);
    } else {
      // If it's the first week of the current year, go to the last week of the previous year
      const prevYear = year - 1;
      const prevYearWeeks = getISOWeeksInYear(new Date(prevYear, 0, 1));
      setWeekNumber(prevYearWeeks);
      setYear(prevYear);
    }

    // Reset leaveSelections for the new week
    const resetLeaveSelections = rows.map(() => ({
      sun: null,
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
    }));

    setLeaveSelections(resetLeaveSelections);
  };

  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };
  const fetchTimesheetData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/Emptimesheet/${user.Data.empId}`
      );
      const data = await response.json();

      // Filter the timesheet data based on the selected status and week
      const filteredData = data.filter(
        (entry) =>
          entry.status === status &&
          entry.week ===
            `${format(selectedWeekStart, "dd/MM/yyyy")} - ${format(
              selectedWeekEnd,
              "dd/MM/yyyy"
            )}`
      );

      setDaySum(calculateWeeklySums(filteredData));

      const formattedRows = filteredData.map((entry) => {
        const hours = entry.entries[0].hours || {};
        const total = entry.totalHours || 0; // Use totalHours from JSON response

        return {
          project: entry.project._id,
          subProject: entry.subproject._id,
          task: entry.task.name,
          status: entry.status,
          sun: hours.sun?.time || "",
          mon: hours.mon?.time || "",
          tue: hours.tue?.time || "",
          wed: hours.wed?.time || "",
          thu: hours.thu?.time || "",
          fri: hours.fri?.time || "",
          sat: hours.sat?.time || "",
          total, // Assign totalHours to total
        };
      });

      setRows(formattedRows);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    }
  };

  useEffect(() => {
    fetchTimesheetData();
  }, [status, weekNumber]);

  const isSubmitted = status === "pendingRequest";
  const isSubmitDisabled = currentDate < selectedWeekEnd;
  const isPlusDisabled = selectedWeekEnd >= currentDate;

  return (
    <div>
      <Toast ref={toast} />
      <div
        style={{
          color: " #00000080",
          fontSize: "18px",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          Status:
          <span style={{ color: "#EFB034FF", paddingLeft: "10px" }}>
            <Dropdown
              placeholder="select status"
              value={status}
              options={statusOptions}
              onChange={(e) => setStatus(e.value)} // This triggers useEffect and fetches new data
              optionLabel="label"
              optionValue="value"
            />
          </span>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            icon="pi pi-minus"
            onClick={decrementWeek}
            style={{
              marginRight: "5px",
              backgroundColor: "#29295FFF",
              border: "1px solid #29295FFF",
              height: "25px",
              width: "25px",
            }}
            disabled={weekNumber === 1}
          />
          {format(selectedWeekStart, "dd/MM/yyyy")} -{" "}
          {format(selectedWeekEnd, "dd/MM/yyyy")}
          <Button
            icon="pi pi-plus"
            onClick={incrementWeek}
            style={{
              marginLeft: "5px",
              backgroundColor: "#29295FFF",
              border: "1px solid #29295FFF",
              height: "25px",
              width: "25px",
            }}
            // disabled={isPlusDisabled}
          />
        </div>
      </div>

      <div
        style={{
          marginTop: "10px",
          border: "1px solid #EBEBEB",
          padding: "15px",
          borderRadius: "5px",
          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
        }}
      >
        <div>
          {!isSubmitted && (
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "5px",
              }}
            >
              <div style={emptyStyle}> </div>
              <div style={emptyStyle}></div>
              <div style={emptyStyle}></div>
              {["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map(
                (day, i) => {
                  // Calculate the current day in the week
                  const currentDay = addDays(selectedWeekStart, i);

                  // Convert dates to only the date part (ignoring time) for comparison
                  const formattedCurrentDay = new Date(
                    currentDay.setHours(0, 0, 0, 0)
                  );
                  const formattedStartDate = new Date(
                    new Date(startDate).setHours(0, 0, 0, 0)
                  );
                  const formattedEndDate = new Date(
                    new Date(endDate).setHours(0, 0, 0, 0)
                  );

                  // Check if the current day is before startDate or after endDate
                  const isDayBeforeStart =
                    formattedCurrentDay < formattedStartDate;
                  const isDayAfterEnd = formattedCurrentDay > formattedEndDate;

                  // Disable logic for the dropdown
                  const isDisabled = isDayBeforeStart || isDayAfterEnd;

                  return (
                    <div key={i} style={dropstyle}>
                      <select
                        name={day}
                        value={leaveSelections[0]?.[day] || ""}
                        onChange={(e) => handleLeaveChange(e, day)}
                        disabled={isDisabled} // Disable dropdown if the day is out of range
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                          borderRadius: "5px",
                          border: "1px solid #EBEBEB",
                          padding: "0.5rem",
                          boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                          backgroundColor: isDisabled ? "#f5f5f5" : "#fff", // Change background for disabled state
                          color: isDisabled ? "#aaa" : "#000", // Change text color for disabled state
                          textAlign: "center",
                        }}
                      >
                        <option value="">Select Leave</option>
                        {leaves.map((leave, index) => (
                          <option key={index} value={leave.code}>
                            {leave.name} ({leave.code})
                          </option>
                        ))}
                        {leaves.map((leave, index) =>
                          leave.halfDayCode ? (
                            <option
                              key={`${index}-half`}
                              value={leave.halfDayCode}
                            >
                              {leave.name} ({leave.halfDayCode})
                            </option>
                          ) : null
                        )}
                      </select>
                    </div>
                  );
                }
              )}

              <div style={{ width: "7.25%" }}></div>
            </div>
          )}
          <TimesheetLayout />
          {rows.length === 0 ? (
            <p>No Timesheet</p>
          ) : (
            rows.map((row, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "5px",
                  marginTop: "10px",
                }}
              >
                <div style={rowStyle}>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="pi pi-trash"
                      style={{ color: "grey", width: "10%" }}
                      onClick={() => !isSubmitted && deleteRow(row.id)} // Disable delete if status is Submitted
                    ></i>
                    <Dropdown
                      placeholder="Project"
                      style={{ width: "90%" }}
                      value={row.project}
                      options={projects.map((project) => ({
                        label: project.label,
                        value: project.value,
                      }))}
                      onChange={(e) => handleProjectChange(e, index)}
                      disabled={isSubmitted} // Disable dropdown if status is Submitted
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <Dropdown
                    placeholder="Sub Project"
                    style={{ width: "100%" }}
                    value={row.subProject}
                    options={
                      projects
                        .find((p) => p.value === row.project)
                        ?.subProjects.map((subProject) => ({
                          label: subProject.subProjectName,
                          value: subProject.subProjectId,
                        })) || []
                    }
                    onChange={(e) => handleSubProjectChange(e, index)}
                    disabled={isSubmitted} // Disable dropdown if status is Submitted
                  />
                </div>
                <div style={rowStyle}>
                  <Dropdown
                    placeholder="Task"
                    style={{ width: "100%" }}
                    value={row.task}
                    options={
                      projects
                        .find((p) => p.value === row.project)
                        ?.subProjects.find(
                          (sp) => sp.subProjectId === row.subProject
                        )
                        ?.tasks.filter(
                          (task, index, self) => self.indexOf(task) === index
                        ) // Remove duplicates
                        .map((task) => ({
                          label: task, // Use task name directly as label
                          value: task, // Use task name directly as value
                        })) || []
                    }
                    onChange={(e) => handleTaskChange(e, index)}
                    disabled={isSubmitted}
                  />
                </div>
                {["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map(
                  (day, i) => {
                    // Get the current day in the week
                    const currentDay = addDays(selectedWeekStart, i);

                    // Convert both dates to only have the date part (ignoring time) for comparison
                    const formattedCurrentDay = new Date(
                      currentDay.setHours(0, 0, 0, 0)
                    );
                    const formattedStartDate = new Date(
                      new Date(startDate).setHours(0, 0, 0, 0)
                    );
                    const formattedEndDate = new Date(
                      new Date(endDate).setHours(0, 0, 0, 0)
                    );

                    // Check if the current day is before the startDate or after the endDate
                    const isDayBeforeStart =
                      formattedCurrentDay < formattedStartDate;
                    const isDayAfterEnd =
                      formattedCurrentDay > formattedEndDate;

                    return (
                      <div key={i} style={inputStyle}>
                        {leaveSelections[index]?.[day] ? (
                          <div
                            style={{
                              textAlign: "center",
                              padding: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            {leaveSelections[index][day]}
                          </div>
                        ) : (
                          <InputText
                            name={day}
                            type="text"
                            value={row[day]}
                            onChange={(e) => handleInputChange(e, day, index)}
                            style={{
                              width: "80%",
                              borderRadius: "10px",
                              boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
                              textAlign: "center",
                            }}
                            min="0"
                            max="24"
                            disabled={
                              isDayBeforeStart || isDayAfterEnd || isSubmitted
                            } // Remove Sat and Sun from disabled logic
                          />
                        )}
                      </div>
                    );
                  }
                )}

                <div
                  style={{
                    width: "7.25%",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#DEE1E6FF",
                    color: "#29295FFF",
                  }}
                >
                  {row.total}
                  hours
                </div>
              </div>
            ))
          )}

          <CalculateHour daySum={daySum} />
        </div>

        <Buttons
          setRows={setRows}
          rows={rows}
          status={status}
          setStatus={setStatus}
          user={user}
          isSubmitted={isSubmitted}
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          fetchTimesheetData={fetchTimesheetData}
          setDayTotals={setDayTotals}
          projectMap={projectMap}
          subProjectMap={subProjectMap}
          taskMap={taskMap}
          selectedWeekStart={selectedWeekStart}
          selectedWeekEnd={selectedWeekEnd}
          leaveSelections={leaveSelections}
          setLeaveSelections={setLeaveSelections}
        />
      </div>
    </div>
  );
}

const rowStyle = {
  width: "14%",
  color: "#FFFFFF",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};

const inputStyle = {
  width: "7.25%",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  paddingTop: "8px",
  paddingBottom: "8px",
  border: "2px solid #EBEBEB",
};

const dropstyle = {
  width: "7.25%",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "10px",
};
const emptyStyle = {
  width: "14%",
  color: "#FFFFFF",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};
